import React from "react";
import { Config } from "../config/default.js";
import { ethers } from "ethers";

//web3 stuff
import { UnsupportedChainIdError } from "@web3-react/core";
//import connectors from web3React i.e. injected, ledger, trezor...
import { injected, walletConnect } from "./connectors";
//import custom errors
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";

//media
//import logo from "./logo.svg";
import logo_metamask from "../media/logo_metamask.svg";
import logo_walletconnect from "../media/logo_walletconnect.svg";
import { Spinner } from "./Spinner.js";
import { Power, X as XClose } from "react-feather";

//organise connector names into strings for referencing later
const connectorNames = {
  Injected: "MetaMask",
  WalletConnect: "walletConnect"
}
//organise the connectors to access them w.r.t. their string names
const connectorsByName = {
  [connectorNames.Injected]: injected,
  [connectorNames.WalletConnect]: walletConnect
}
//organise corresponding logos for each connector
const connectorLogosByName = {
  [connectorNames.Injected]: logo_metamask,
  [connectorNames.WalletConnect]: logo_walletconnect
}

//make react-feather icon into a component
let powerIcon = <Power className="power-icon"/>;

//get the convenience library (ethers) for the Web3ReactProvider
export function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library
}

//deal with errors
export function getErrorMessage(err) {
  //*debug*console.log("getErrorMessage readout:");
  //*debug*console.log("GEM err: ", err);

  if (err instanceof NoEthereumProviderError) {
    return (<p>No Ethereum browser extension detected, <a href={Config.metaMaskLink} className="metamask-link" target="_blank" rel="noopener noreferrer">get MetaMask here</a> or visit from a dApp browser on mobile.</p>)
  } else if (err instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network! The Heroes are on the Ethereum Mainnet, please connect there!"
  } else if (err instanceof UserRejectedRequestErrorInjected) {
    return "Please authorize this website to access your Ethereum account."
  } else if (err instanceof UserRejectedRequestErrorWalletConnect) {
    return "Please authorize this website to access your Ethereum account."
  }
  // else if (!!err && err.code) {// && err.code.toString() === "-32002") {
  //   return "Please check you've connected and unlocked MetaMask! We're trying to access your accounts..."
  // } 
  else {
    console.error(err)
    return "Please check you've connected and unlocked MetaMask! Check the console (F12) for details"
  }
}

export function ConnectOptionsModal({
  connector, 
  triedAutoConnect,
  activatingConnector,
  setActivatingConnector,
  activate,
  error,
  onOutsideClick,
  onConnect
  }) {
  //this should pop up as a modal when the 'connect wallet' button is clicked
  //attach a ref to it, to track the next click locations
  const connectRef = React.createRef();
  
  //close the modal by clicking anywhere outside it
  React.useEffect(() => {
    const checkClickLocation = (event) => {
      if (connectRef.current && !connectRef.current.contains(event.target)) {
        //clicked outside -> close the popup
        //*debug*console.log("onOutsideClick: ", onOutsideClick);
        onOutsideClick();
      };
    };
    document.addEventListener("click", checkClickLocation);
    return () => {
      document.removeEventListener("click", checkClickLocation)
    };
  }, [connectRef, onOutsideClick]);

  return (
    <div className="connect-modal-underlay">
      <div className="connect-modal-container">
        <div className="close-button-container">
          <button onClick={() => {onOutsideClick()}} className="btn-close-button" style={{border: "none"}}>
            <div className="close-button-icon">
              <XClose size={32} className="x-close-icon"/>
            </div>
          </button>
        </div>
        <div ref={connectRef} className="connect-buttons-container">
          {Object.keys(connectorsByName).map(name => {
            const currentConnector = connectorsByName[name];
            const activating = currentConnector === activatingConnector;
            const connected = currentConnector === connector && !error;
            const disabled = !triedAutoConnect || !!activatingConnector || connected || !!error;
            //*debug*console.log("ConnectOptions readout:");
            //*debug*console.log("COM currentConnector: ", currentConnector);
            //*debug*console.log("COM connector: ", connector);
            //*debug*console.log("COM activating: ", activating);
            //*debug*console.log("COM !!activatingConnector: ", !!activatingConnector);
            //*debug*console.log("COM connected: ", connected);
            //*debug*console.log("COM disabled: ", disabled);
            //*debug*console.log("COM !!error: ", !!error);
            //*debug*console.log("COM error: ", error);
            if (!!error) {
              console.log("COM error.code: ", error.code);
              console.log("COM getErrorMessage: ", getErrorMessage(error));
              console.log("COM error: ", error);
              console.log("COM error instanceof UnsupportedChainIdError: ", (error instanceof UnsupportedChainIdError));
            }

            return (
              <button 
                className={
                  activating ? "connect-modal-button btn-activating" :
                  connected ? "connect-modal-button btn-connected" :
                  "connect-modal-button"
                }
                style={{
                  cursor: disabled ? "unset" : "pointer"
                }}
                disabled={disabled}
                key={name}
                onClick={() => {
                  //*debug*console.log("currentConnector: ", currentConnector);
                  //*debug*console.log("connector: ", connector);
                  //*debug*console.log("activatingConnector: ", activatingConnector);
                  //*debug*console.log("connected: ", connected);

                  //*debug*console.log("setActivatingConnector(currentConnector)...");
                  setActivatingConnector(currentConnector);
                  //*debug*console.log("activate(connectorsByName["+name+"]");
                  activate(connectorsByName[name]);
                  //then close modal
                  //CHECK IT'S CONNECTED FIRST
                  onConnect();
                }}
              >
                <div className="connect-modal-button-content"
                  
                > 
                  {activating && 
                    <div>
                      <Spinner color={"black"} style={{height: "25%", margin: "auto" }} />
                      <p>Check you've completed signing in to your wallet...</p>
                    </div>
                  }
                  {connected && (
                    <span role="img" aria-label="check">
                      ✅
                    </span>
                  )}
                <img 
                  style={{width: "30%", maxWidth: "50px", margin: "auto"}} 
                  src={connectorLogosByName[name]} 
                  alt={connectorsByName[name]}
                />
                {name} 
                </div>
              </button>
            );
            })
          }
        </div>
      </div>
    </div>
    );
}

export function OpenConnectModal({
  connector,
  triedAutoConnect,
  activatingConnector,
  setActivatingConnector,
  activate,
  deactivate,
  error,
  active,
  account,
  connectMsg,
  buttonClassName="btn-type-a"
  }) {
  //a single button to display the ConnectOptions modal
  //is the modal open?
  const [isOpen, setIsOpen] = React.useState(false);
  //*debug*console.log("OpenConnectModal: isOpen: ", isOpen);
  // const [dumbState, setDumbState] = React.useState(0);
  // console.log("dumbState: ", dumbState);

  const openModal = () => {
    //*debug*console.log("openingModal");
    setIsOpen(true);
  }

  const closeModal = () => {
    //*debug*console.log("closing connect modal");
    setIsOpen(false);
  }
  //const [refState, setRefState] = React.useState(0);

  // function useForceComponentRefresh () {
  //   console.log("refState: ", refState);
  //   return () => {setRefState(refState + 1)};
  // }
  
  // const forceRefresh = useForceComponentRefresh();

  return (
    <div className="connect-modal-open">

      <button 
        onClick={() => {
          if (!active && !error) {
            openModal()
          } else if (!!error && error instanceof UserRejectedRequestErrorWalletConnect) {
            //*debug*console.log("forcing refresh...");
            window.location.reload();
            //*debug*console.log("deactivating connector: ", connector);
            deactivate(connector);
          } else {
            deactivate(connector);
          }
        }}
        className={buttonClassName}
      >
     {/*{ !isOpen ? "CONNECT WALLET" : ""}*/}

        { (!active && !error) ? connectMsg : 
          (!!error) ? <span>Connection Error, Click to Try Again!</span> : 
            <div className="connect-modal-open-button-content">
              <p className="text-no-margin-top text-no-margin-bottom">
                {account.slice(0, 6) + "..." + account.slice(account.length-6)}&nbsp;
              </p>
              {powerIcon}
            </div> 
          }
      </button>

      {!!error && console.log("CWB error found: ", error)}
      {!!error && console.log("getErrorMessage: ", getErrorMessage(error))}
      {!!error && <p className="web3-error-descriptor">{getErrorMessage(error)}</p>}
      { isOpen && (
        <ConnectOptionsModal
          connector={connector} 
          triedAutoConnect={triedAutoConnect}
          activatingConnector={activatingConnector}
          setActivatingConnector={setActivatingConnector}
          activate={activate}
          error={error}
          onOutsideClick={closeModal}
          onConnect={closeModal}
        />
      )}
    
    </div>
    );
}
