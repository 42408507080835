export const whitelist = {
  "0xAd0F4092d1251529D9f93090862155f29b0a2DcD": {
    "hash": "0x30fb5a907b392eedee0d1658ba246f5cc0195c72f43660620ed752880ff5c21d",
    "sig": "0xf1c54921773fd03aaa1d03aaad32bb8ce9d9e15cd987b81d5008d2b4bbbcf5ae4a432c542640393bc48d712dcb2ece25cc71a7c5b82ef6de65d64fed932760a11c",
    "quota": 1
  },
  "0x234c235867CFFab5f26f02c4442B0b1c36b187e4": {
    "hash": "0x801e4abf380811adba27a855d5b84ef667ea6674623852502bd6c7e9649f53a0",
    "sig": "0xbedc7cb36f4bf68315d6784a329c4765c3d84a0ae063c782e2c8bb8ce8e19c3223797057077c7b4511bc47091ee7422eb08730d20554018f75d5d101c98c64801b",
    "quota": 2
  },
  "0x17DB336eE95EC4301a486F4633cEEc52E54F77EB": {
    "hash": "0x008b2c858f1b7d2cbe4ece89d76855e9ed3836df8172876ec669dad0cf7ef4fe",
    "sig": "0x06124f3e02014e3af00808eaac4230137b3ba9366a5e94ceed13f63b70eab76861309ab5987cb53de4991628c10f2ca40eaf34f3bec2f06c10668411eb7494351c",
    "quota": 1
  },
  "0xb6FE09082bfA4BcA57C0e0631E1759D24C9abaE2": {
    "hash": "0xa21aedf571e05254afaa02a829dd0091c5c59174e7efe0b71928391388cd449c",
    "sig": "0x797e9660c2fb07f6ce91a40aa75851cf2c92cf61a429a43505f93e4f641d67321f90a6ce3251301b733c64e6d8f2773fb4eff4ab90a3c993c4b35ce55483052b1c",
    "quota": 1
  },
  "0x0aE63abf7F2200308924Cd3604FdB52bB5925C73": {
    "hash": "0xdc173cf3bf14de15751a478ce1c880d34b3a248c9340225c4cb9f07400841ac5",
    "sig": "0x095cd96a85943d0e4ac2e3552c2f7a0522eaa4e3fa09a7289194a1af54d8554d0b5f3deef8b3d7e5357d9d8795e1183e5659697194edacd8253efbe7cd8a15ce1b",
    "quota": 1
  },
  "0x7f4aDE051eAeeFa2BFDFD5f47BB606aE79ADb837": {
    "hash": "0x899b917ddd92f1eb98579a7eb4bdd96dc4e1ed262ca2380dc6aede5ba95c8901",
    "sig": "0xc104f2472a5ee587bad173f7d21560c8ace26a3a2ec2fd829939d6ca82bfa9a53a618fcf1f0b981070ac0d7c3785e0b467b2ec47d83dabb9a422d393d5c842e21b",
    "quota": 1
  },
  "0x42Ee5CFe8a3fB341DC8aD7dE5ad6D0E2A2F2506E": {
    "hash": "0x7d5c3e29479a8f49ebd12d1105560ab82d88a3c9611a06cbdde33dd3291c39ce",
    "sig": "0x82e7c1216ed48bbaa3e713b3f41dd97967c5286a6ac856431971c008139d5e263807b1038360c6fe628fa71d6c481760f6836899cfcd3b129654b091f4123c3f1c",
    "quota": 1
  },
  "0xC212Fa2b9C4e07548d8e2809612063d38d168495": {
    "hash": "0x70abff376082679dc1ed0a3680b368857b2e7ea940966f909ded1724140dcb52",
    "sig": "0x6f26de4c93da9d833c29d1f07c423839f8ab0cf6abe696582c345e504ed3320a270b0baa9e098993c8c689724d4b6a0088236e5dc946a385cdc372f8cb54dae91c",
    "quota": 1
  },
  "0x9e90A406C42b9177C370D453369f79f82E3FEA7b": {
    "hash": "0x3bc3d4d619387f89d27527287db71df1bfb2b426c5c04eb410fbb2a0176dbc6b",
    "sig": "0xd629854f09cb4713f271c6cab79f12e9e996b478ad0829dd31dfc8a34af044534269f64b328484f5e4a2b99ebc675723f33502dbc170164b251a8c66c898a3dc1b",
    "quota": 1
  },
  "0x1427Cc00080f17dD10AA59e629eA3e2de14608c0": {
    "hash": "0xc3c90d366cafe4051380eb559cba5c1cb6da849205141e01808bf5cc64fa9bc9",
    "sig": "0xf31ab6d29342157e6ee0e882a2faa25d463026fdb468410f3050afc4732df55263e75dc04d2df8f8380641562013673778456b70239123cf00f7a326a87c724c1c",
    "quota": 1
  },
  "0x9DBac2a76A2A69007CB04EAAd4D9F5a566885907": {
    "hash": "0xc4fdfae387faeecadf7065a261b7866f6e9858f71e0b87d2ec44f8a427ecb376",
    "sig": "0xe7b2f448d142bc00de3f21b488f7cbfcd8c6fcfb38accca3b8f0bd974a95dd361280006379b526d663dc7e8f736fce3525367306cab75a38935e3a315b7e498d1b",
    "quota": 1
  },
  "0x80e6d5b08d2f917d3A08e77D33Ed88545c6DE22b": {
    "hash": "0xa8c2f9f85d0668360c08b7629f93d4b24c5904a0aced4fe53dae34b7debcf68a",
    "sig": "0x1b46f98c11f9e6bd45a86870e296dc744003914a2b3613551e98f99d57d2c50f21bd6b7b4536896c24661d77f5a7b0bcd269b5a3671a7fa1b14228fc9b418a261b",
    "quota": 1
  },
  "0xEa120fe4438Ea888DCDA31B9eDFb925C12072C68": {
    "hash": "0xbd89fb69e370f733d967c07209272427742b9258efc32f53578332ad0148a37f",
    "sig": "0x22a4459dafac76d050b3e108b526a991dab1ded66dc65228795ccfdc6252f43742834a1cb48fd6395c44aa4ba9c555aa6a64ae01d5bd7c798fcea2760b01870e1c",
    "quota": 1
  },
  "0x4d76a99711771355dFba69713b11DcA783936E38": {
    "hash": "0x020867980ebfbbcf0c2291915c8b1716799aa87c1d6be4306c9f5caf2d9af290",
    "sig": "0xf7cd539fdcf0913a95a0c5b66dd1dd0702d64ecec05c1f85012f1a78639d6c4c2c8be66a08ed35836fc9af30aaf9362bfd9d374ce40efc60e0bf712b4d27a4751c",
    "quota": 1
  },
  "0x869eC1B9acD6eeF44987341B76fc306E491b07d8": {
    "hash": "0xae6d941d33c9895e3b9b333c9c5af8f017db4a89e8befa4c7abd9b5d670ff01e",
    "sig": "0x82a28e2691de3803e67e1ec3586e6084f1a20cb1db25c3a52b9960dd3b445ab95186a07a8f2f243620f4c20c33b96a9f180af9e46b69ea292f17b2e8c434768a1b",
    "quota": 1
  },
  "0x18afd6a8A52C18B552BDbD20fff477FFaa01A6E2": {
    "hash": "0x0f68a3548ac8ccc0bfb0770da83ba3e773f974896b7566b939c3f486fe430fda",
    "sig": "0x693b4a23477265f5a1d50a249ac857e200da2dfc8b8e818f98b5ff91b5e061164310b2125a8a35057b180ff314a5808422e73f2fba7efcb95ee49f2153ffc1f61c",
    "quota": 1
  },
  "0x23D344A3fe9450753324523425eAc8BB6754eb8e": {
    "hash": "0x19147df031867cfd21379ddfa3e7ccb714e068c9500930e9d5954329977ac82a",
    "sig": "0xd11af446ba908d3711a1cf706bb40c1d931ad37e407014fe8bb7420217a578e0411a147a6831cee4b90e6db2de3d226d4a1bcfefc900295b22f35d3595954c591b",
    "quota": 1
  },
  "0xD88130F6f573b5B7e8D4D493Aa70C7e3dd2f3b84": {
    "hash": "0xdb066d9140fc9eb019d10e96f7a885de1759f1854c16a02f0e92bc85a5c9bea6",
    "sig": "0x9d7cf7668f32ba0bfe2c3e6d60ed63720710c8ae21e2a562a5bdd16e92c938980ac95248a008d43bced6dc4835ebbc91f63bd1eab01cbeab1a4bf35f747ac77a1b",
    "quota": 1
  },
  "0xDA347F2fd040482597d101Ff2Fd3a7861261B320": {
    "hash": "0x7e491be73da787772842b21943b86d03385c56e652af78e1d16b770b84f78b76",
    "sig": "0x4c90213efab9c0a4db80ea699eceee153bbe0c531a02616ea463b57375fa32b351c11598f9ab89950c3b02b47efca276519ea505783d89f74af90ce8569f96c21c",
    "quota": 1
  },
  "0x4A9a9C576EF773E274C2F5cEdf0F00f4e9a6643B": {
    "hash": "0x882f709505f407c26c3198266c96037178c6bf0f9e16830f8d144d6aa398e0e9",
    "sig": "0xb0ad22ff8dee4deee10b8f498ed4b434ee60ef8db89a79fa71feebca5079f54b45c639313f9da087c73ba8a2509aaffb681db34e2957722406c526d597ffdc921b",
    "quota": 1
  },
  "0xFeBeC31bDf1972c42E683476B89b40D9871A62f5": {
    "hash": "0xc1e0f04fea5d6a7908a5014a4737a941442d7342c407d3b583cb8cd1a1ba844e",
    "sig": "0x90522d990231b033c2e88b68765e7432800c73052a138a80a35267ef6d51acce5665b6dfeecf3be41e8ad7db5895f03fb17e60a5e5c3154595e7755578e2c0131b",
    "quota": 1
  },
  "0xcb8eD45FDA5f573af196F3EF73bEbD9bC5df3ae8": {
    "hash": "0x5d1d4ebeb9039d705670fa8dac69c423b4501e38f664c0cf3252760f00dcbed7",
    "sig": "0x63320c9ea8dc080ff13491bd6f4befb9fc58f697ca1daa139cb77d2af30634e85ae2d4925e970ec5ab8ceb57a18d7158ef2ff16643991c77827e0f226a1048101b",
    "quota": 1
  },
  "0x631040c35B5971E764549E2d975381cFF27AfbA3": {
    "hash": "0xb85b1374828cd0edf182cc77d2079b3fd53969ef09f60994f3ab1787b1b6f7a8",
    "sig": "0x77eb04294a17729103b4a4ebae93a0f021cde23b0891aa1937d997def136bee5475fcf35f74774ba265327097515ffecb53eb4efa95fde1bed8b3b6285c0665b1c",
    "quota": 1
  },
  "0x330F28C48B11256eb75138d2376ccaa0923B30D7": {
    "hash": "0xca404288328c691401eba44e58d55c29ec442cef7b1beb8238ed8d7c2cdd09b1",
    "sig": "0xeb96383baecc5265918c0a7b60a56bd5c84097570bd6b608181f6b2a7e3cc7aa03c148429bde58dd1e26330f73574acb065af04a7675a28043de077590620e1d1b",
    "quota": 1
  },
  "0x1f8C8bb4971C42e441E2Fc3662bCAC89052d4373": {
    "hash": "0xf3f3c87aa35f77e1aedd8de07ff8cd1fe616bdd8170163d982c4f95ae220912f",
    "sig": "0xdd73810888d5ce34c1edd11d64b3e9b701a07f10a577200f5fc45ede28b0dabf20189254bdd198145543fad37f5895f0098b37e53d3415bb965a430d89727a951c",
    "quota": 1
  },
  "0x47CA5557f8Fd29943611D56dc74c1470c1e53Ab7": {
    "hash": "0x31bdb4504983d742e93a607c9d22babf71a33cd45e21fe301ccee1593aee3ab0",
    "sig": "0x684164aaee63a7b70758c9df2c023a9201a557e534c884dc1fbc0e02fef08cc81509e189bab054305943139883000faf2608254905f6ad6aec3e06add83aef291c",
    "quota": 1
  },
  "0x92597C1C1d655CF92526952Dd08b7d4A7189Af3B": {
    "hash": "0x92276ca8509c54a605a0c9fab40f99d20a0d281481f08c8e6afe3d71e4fbc42b",
    "sig": "0x5230e2743dea869f70b6ee9abe63f848bd2ab27c35b583a0752e59dfe6535a571e14a740ffe4a9ca8b041fbbc98e3e74477d130b95f12515d008bd39e599c2471b",
    "quota": 1
  },
  "0xcEbeF1aa2923B813a29d02D6de49391b06e0C380": {
    "hash": "0x7362f7baf4b8df20b949cb9b40e795fe8788be4a69134144b317b918441ed3fe",
    "sig": "0x2b559000c7cc76d24155610616e10cd191762f9ed6ed51a0c9b441c847922b7951f8779b7abecda114b0d3b8762cf3bbe81749e8f638ce3f8c9410b94db9eb401b",
    "quota": 1
  },
  "0xeFdfb22c09DAD726371167F6650e5c54038301a4": {
    "hash": "0x26e28101340d5f69fea90421d292244b326e1f3f4c4c10457783d5b1034799d7",
    "sig": "0xa0b202f79f7819e864cf9a05ef62a9bfdda65e96f470dd3d5b5255ada05998a00d12755f430bd5877d1c5003347f368d1be2960af9d4c1e47ebd373f33d3b4911c",
    "quota": 1
  },
  "0xFD30aEFCFe158dc4fC46E1CC269cA02227Cc235D": {
    "hash": "0x99f9bb6946b6318719ec460d920bf865ef0ced02557b49e30c0e590cc1043095",
    "sig": "0x4193fa60324356d4cbff5439ea3af748e292f1726c7033236a14458524cebe9f40192fe31bc95540f08b068d5742d6a1e466a62ea8482a4bccee92e57994929a1c",
    "quota": 1
  },
  "0xdE236fc0e74f658f2d360231d1872b89CAc5484C": {
    "hash": "0xc2dc2a1bb0ca24f279a3f6a1db825d213678429406b598c93877f5894eaff2df",
    "sig": "0x3bbbb47d904c1ed3f44d3302ba368eb27fe5afaecc0c0fe6093887e9def8be2d55a45ba346a5affa1209e5afc2b66b481589409a850b9f07c01a64bd897e994a1c",
    "quota": 1
  },
  "0xb47A59a6eFaB3358a1979f6d2bB6717a04c3CD36": {
    "hash": "0x5f3cd5ab12850ebaf6efe234d13b4a8ba3422f94f2e5441e6aed6a1e19769f8b",
    "sig": "0x631e172511aad738bbe6ca5725f8692a01c6ccc33bf171e1b60335cf901f040c1b43ce51d646e8a7f6650b63d87329c0c62cd8d654bc659843013957da1497a41c",
    "quota": 1
  },
  "0x124225a3CaA4A9917F7a539548834d45B86ACb42": {
    "hash": "0x78c9ffef5aeb040c44fb12f68ef06deec97e8e63abf29de5723088fb57352470",
    "sig": "0x4f7a6320e728b8c4826077aa7708a6d46719fb303eb8192ae65dd0b13083bcac19908b753b40bac848f3bb55114f91c3542e309e3d0be2fd0bf38dee9bf2d97e1c",
    "quota": 1
  },
  "0xB1AB0f493Ec62d1D0AB14B73F4758B13616ed0f4": {
    "hash": "0x3e3eb904aba95c0b707914e119dbf2a06918f0c27786d51db63290206996740c",
    "sig": "0xae24f15935bd7fd63b0d32999a5aa9b5ba7c357bc9ee35fcac8170b1991da2bc5ef9d85d91a76a6e0ace85149e8feebf9f1b9d855385b01262f3b8b2508e8a511c",
    "quota": 1
  },
  "0x36Ad64A158B2270ecD2EaF2Ae400C7443B418E3B": {
    "hash": "0x14e543b104dbad5f9d220b77f7f0161e914822d11ce9cf8ce627d718adf60ea9",
    "sig": "0x16ad8dbd859b9e7b2928b51a64613738d5f4394790ccd6fe948b494f761faf9b3212e45b4f4499807488510c67bc130a1d9821314e57c30b8b86882358cc21f81b",
    "quota": 1
  },
  "0xb83C32379Bef27Da96ce4D78C62c34F9B461e387": {
    "hash": "0x80ced9e1c095b6f1b0cdd662adaf0c742319eba227529f04d6a3318d43633f4c",
    "sig": "0x1be9520152168fc5ae83c896bb036d0126e196b74f2b9f92129c61eced9b830d65cc7157604bf4909c57de95d257a568dac26684753c1202ec6e67eb07547c621c",
    "quota": 1
  },
  "0x1112b01C6d4C4AEdB6dF12Ca00e1b56413EC325E": {
    "hash": "0x12fd21731cf1ae7a17c85f470d684ac429550032c4c5173aebe3a04597a28f7d",
    "sig": "0x4f8d289e27867e568a083595b2fd609bf3cd6b738da19c5b37609cffc1cb394042f6770a0ac7ea33bc9103852aff0a1cfbb118cc2ffa1f862bb5656e13cc61c91b",
    "quota": 1
  },
  "0xa102FC2558223a9ee1CD8C51D582F42441Fd84C7": {
    "hash": "0xa2d4340afd9cafae1322f3bc3a06c96416b52b18713757173af4cba94135e986",
    "sig": "0x5cc1eba3357e3e5b957f9dbc61457e61a3ffca01885475dede8ba122d41cb261617d5efdea89501c0c8fea1fa57a47c546ff555998947d29aeb2bbdf1d0aac981b",
    "quota": 1
  },
  "0x6d275fe223dB8da40Ed494B63b1Cd06877651A5C": {
    "hash": "0x885d67e060c83ac60178dd15cc949caceb73858faa5d2677be9d6b8886f3e08c",
    "sig": "0x77ff0e56d8700ee80f1732ac266273cd7fef0c1417084f02af09d47bef617d907e29342369c4e12793c38f40fe99fcfa4344e3bc7daceac04d4259f026b809c11c",
    "quota": 1
  },
  "0xB2338e8c6c668154476b6575a07B28F4675E4ffc": {
    "hash": "0x721e807e107bb087780720361c6e9ceac4efd3357e28d7a7effec28aba819157",
    "sig": "0x105796bd4e17c9c491194dd570a93134a1dc384001ada99f8119c0e98df5c64a6965de1f18c7b8dd07389e51afd85952f8d4679f27eb99f79b50930b14a07ead1c",
    "quota": 1
  },
  "0xCF883872328E1F354e8c8E36B487e84f92092aAc": {
    "hash": "0x0c446dffda6cb0bdadf81df13c217e34098a809b6b37523f1a643120a25f7379",
    "sig": "0x8ca7ca7c939540bdad1ab170836e951932752da4e93da67bda34bea6d2eed2694d7bbd67ee1ef2d292980cca9c9abed1282e75422ae43df100116525e77c495b1c",
    "quota": 1
  },
  "0x01DaC505E000d4B87e427271Eac85B4a46B5141D": {
    "hash": "0x3cf0cdd11c42633b220358bc7bdb380b361f80905995c7ae57e12bad41c034c8",
    "sig": "0xe7cc914c464b6f2f3aeb03515cb778ae54e41325318eaf35936581f99f6400b240dfbf5a4c809a8732574d0bd9a4015ea6d5a5cd8b7d54181ce44f620b6323761b",
    "quota": 1
  },
  "0x17136144999C10439D90A2aC22386595BfEf0527": {
    "hash": "0x6e3cb822e43b46443891f8af157a508132be2ee4f0f1818ed34d72829f97e150",
    "sig": "0xe01cf82c011b8348820c75047b221e7e7193795d729d6010693c075d7852f7d40fea00ca13f93b9a51c56803c7d4e2639dac694643719039b73ccb8d6f6f5f151c",
    "quota": 1
  },
  "0xd677E39e2CF631Cc5128150aC0ee54Ea453e3256": {
    "hash": "0x7393803dde6d98264da4600b9bf25421a252db3300e2464a9d344248638285aa",
    "sig": "0x768ccfa679cb849fb12739b84a0dbb2cf3f86056aa2456a2f1151b250f8129ef53d38a79b11eabe0599f8ab8d5b2edf6a864c4bfe4a07493d03402f62ac50fcf1b",
    "quota": 1
  },
  "0xE7CCfF5De5D5bbc638394D917EBFA6a60Cf38C08": {
    "hash": "0x32ac9cbca76fe9b338d9236935dcd1931f1f0c86104b839ab6b21d898c7546b7",
    "sig": "0x4f57068401d046ef96b2c9ba76273b04ce618fd8c0ef7fd94b8a0bef7c3c2ff117e42fbb71ccf505dc3852610120b03267a21d25e27ccab98dd0802a3e27580d1c",
    "quota": 1
  },
  "0x574857448921B881564bFDCB20b4b554419ed07C": {
    "hash": "0xaa15a3aed460ca92d8609ecd32a35059902bc2300a3481c7d9c9b563888eb16d",
    "sig": "0x5e56c1d50398065c8bac3df02b6d2d3ef941424f22222b3b78df4a90b293ac184e86f6f382b937993b21340cc83e5dc7ef6ffc394c82c1dc0dc3ed470a0d9e001b",
    "quota": 1
  },
  "0x6F5F560Be37535CFD389e0a7A0c9a0e16505b8F7": {
    "hash": "0xcc83819d4dcdb53267d9f897b50076f2957e0accceb9d21f5da0953542576a81",
    "sig": "0xff61e8d6e7f1c880f8fab7b2970fb7b8f75dc60fc4f25b713afd6db1278d89c253e32efef23d20185d969ed9ca7892d058489977301b666e815131b6fb132e431c",
    "quota": 1
  },
  "0x272538C62Cff0F7dB01d53919F46741c7E1c3719": {
    "hash": "0x2cd74ce555f5ceb203796a799d6c2e08436e19904ce25b4289dc9fbf2dc38022",
    "sig": "0xd0460808a29fede614d5cca922af96ddcb3e03c5ea98ec8c3c5d79f68cdda7832c97c1fce5d27bb393165d21c99a8e0f1caf8eb8f033bcc50cedf6b4b2968f041b",
    "quota": 1
  },
  "0x0421Ac7CAC9a369bB4d036229C0aa835346A48e5": {
    "hash": "0x87de30728c37606bb34441b0332849879502e42232ceb11864815cae30478ab0",
    "sig": "0xd4581dbf6dda6f344032437cab21efc6f714b8a6ca570bcfa3ffb65a7168b50a6a8817afbe0ecc25e682bc4cd9f85a166d5aa4774deddc6eefd4bd5fd086d1bd1c",
    "quota": 1
  },
  "0xf5a2026e9da99A90cAaCE15524998E3bDd4BB568": {
    "hash": "0xb114019705b9ae39ad884f27385f59d9e6ab2b9f61d91983eae7edd408f74c58",
    "sig": "0xe374b72fd951fc12852f1e0e53f14eb2c0fe32f8ad5e25f526455b5dd47e5df7414efd1b3adfbfe1079bfae365e94d0bfd8cd377839310e38e19a38c68e536841c",
    "quota": 1
  },
  "0x6184B87721AF4a15A64Bc44FE70BFd7E88b0e910": {
    "hash": "0xf64771cd2d0b617f3371247d3b43518cf28be0ae26ec8753752c1f8767a4677a",
    "sig": "0xc4655edfd5030f25c02d7e18eb133d12c706c76f9b8fa8f2dadf6db0b4a809f133bd75d9ccd4baa3956f7a7408a7bab6c59ea7c88af07e8a7abf7bb8602355a11b",
    "quota": 1
  },
  "0x31Ea77437E6A34877d7433D81A085EEb1a80262e": {
    "hash": "0x8ee6dba26b8dfa0ea03438949026e0a6ee070aa6bbcad75bdaf9b43253f829cd",
    "sig": "0xbdd4f9a6c40c26c0a49ae28131dfa039b46c2552d01f0cb291d0a218d0d366d67954911bd47663668d605c5d3ab634b2a02eef46503f4e1f954cb6927341bbed1c",
    "quota": 1
  },
  "0x01077f529e36785b1d2893Ea0Ef66d5a2431A5f9": {
    "hash": "0x20db84436dcfe72544b31000d5b0e2ba8e2ae3948f198d99fcc52ba4bf756424",
    "sig": "0x610dbca187ad916d3803d7f11f7afa1a8c95fcc5469447129b44037fa62649332d96d2f5949ad130f30687af0a22d5cfdc1d403c2195fef8631af0af2471e00e1c",
    "quota": 1
  },
  "0xb63ea4865cDfedF3A7bCa5Df5BD49F04D59ea348": {
    "hash": "0xe327fc1e86f8b07b150faedfada50a9b12c5e94c39fc65a478bbdd56379ae51b",
    "sig": "0xb8fb00bae41b10e6b6173a372a35961c42787c36604b95ad1357a78b7fdaa3a4027ca75e21157faccb9603d14e02bec0e0a6ced8813e9857ba0b183ee5523f5e1b",
    "quota": 1
  },
  "0x17aff3b20e1ED55218d3f8A4964f6e4766E373F2": {
    "hash": "0xf2b294a54d57772222677387b6140a5c40955a8dd5d92c4edba77f09c09b84cc",
    "sig": "0x9a2d0293657080d8ca5db9538d1004450c6d7f29fa0d14e1497d67653e71f3bb700bed15d8a27929db42e6642d3e0df46b436928ecf59ae9c3291ada2797062b1b",
    "quota": 1
  },
  "0x48D0F208DAcf70A94b5A009feaE0cf33B63B4039": {
    "hash": "0x632fad42f9680aa16f9e2f6b494422dcf5d56c20b9639b94ea85f52c8d896fc1",
    "sig": "0x361343a7dff0c5eded13af252ef4ff12c7af0bcc115ae5030f8d4be2ce320de46cab741eb23e139615451826de416d8c83263ee0a4fcfd13ad8f6e5bdc3fde121c",
    "quota": 1
  },
  "0x5620FD354A13C519D8527e010F55aC517C99a7FC": {
    "hash": "0x499de923e4b1c92e6e2d5d6700e1970530520fe900a6fe1117857002099b9508",
    "sig": "0xbbc9cfedd85d625c7869da5b1fc2d342d71f23e1cb9a81336e1f02f2e9849b8249825797b8ab91e6ef15f9f3fcb2b05c62ba3919dd8d443b84001cfbfcaef49b1c",
    "quota": 1
  },
  "0x8ae66299E2BBC115e0c6904DE4A39E0E46bdD3c9": {
    "hash": "0xecd7f243c9554e10c356e5a1972eec6f248f25c2559d02c6e9da9e323c37c89d",
    "sig": "0x93813808622804d6562012825a5feec57869ee527530bd7022e1c8e8ccf4eee27e2fa4e308c836080e1348b050dc435f25fd36e44da03bc4463ba5f5ecded1091b",
    "quota": 1
  },
  "0xF4607C7c9c5B4047c6dc5b6c44fD321B9681C2b2": {
    "hash": "0x5887c02a9f18e94676e897d962d6d62ce6cc8bf30100939110e9e319a6856f08",
    "sig": "0x6328b336dd102e8e365ca950aea079be67802aa15874d809a78926722cd603735b834549ae9e5305633e66892f39fed042d30c77b3c19b598275b35acb88fc811c",
    "quota": 1
  },
  "0x10b5dD072a4E24C9BF26545B04422995dCC09274": {
    "hash": "0xba3487aa9bcfda75d9b542176b6906373a85eb080982e52351dbf850f6f764d6",
    "sig": "0x9bf6b3fdc4580a8e0c9241748e23f49e262f055756a67bd30225482bbd066c2626452864c6020ed9ee249cf07cb97f56a9a931ae6602d5b2943d7c65b7b1bfff1c",
    "quota": 1
  },
  "0x1E25ee5935a1b6591eba44d37768adA0065e2c65": {
    "hash": "0x503977f4e48a7b80790f30080c5526689c5abd35a4bdd423b42e70cb2e143d99",
    "sig": "0x9d16d098bfc35d0ede429b17550a8e28a4cf076fe00d4993176ae904a595700127f48eb2e7b01d0584df502a43d32d0dc154074456e0ee81653e5c16f098820f1c",
    "quota": 1
  },
  "0xEA65Df929236696ea3148995e65c8AC1DA4BC2F7": {
    "hash": "0xbb89cc6f8071a34cafb272a87646d769d057822b76db0ad08ff41bff872aad6e",
    "sig": "0x279457d9d802e19c0c19c7ab2d8ab54bb29902098e584c37e2b670f266821cdb51b818e60feedcf013a127fe8626e92ef3485f4d7b8a21b69f23e66e7d8317d61b",
    "quota": 1
  },
  "0x4991637a2bb09c58Dbbf73097Bb186979eA8560C": {
    "hash": "0x41784e30a488f720bf626e886a9821f83572cc15c388b6a3aab8320c4315f7d3",
    "sig": "0x080cf999a5a7d34847472cce78804c5fbd486628a3bffbef787a4e63c1bc6f831f03c4ec74044179cebeb8c97da2a2334bda34486da29421b442fd6ef5f675331c",
    "quota": 1
  },
  "0x9eacE7d1F6Bb1360DE5cD75df5eb216cD52e21A1": {
    "hash": "0x8ff6ef818e689121350ef2b23a7e207a5bd8583be633c3627768b2cd85bf4221",
    "sig": "0xd28b2de9317ed25d5b539a9d4e1c85a0ac2c827f97506c36afb61c907688c0610bfd94405a3bf4537a3c91ff5e4b0a8e6d6347b0fd0d504a200211de16dae2871b",
    "quota": 1
  },
  "0x196E7fCDBAc95D1538B2736b678a4CB924Eb3e2a": {
    "hash": "0x2087009eae02901fa296ca06801665afcb7431e46fa9fb798aa6915af1984853",
    "sig": "0xeed54184803635d216e7d8874b801abc55f70d93aa5483f6a5f653a85fc23e1d691ff065339aa2ca84b4ad09ef2f0b4766e4554ccbcdbd288235d7b1a5b957051b",
    "quota": 1
  },
  "0x91430b118378a5dC5dE3B4A02946Cec61696fE07": {
    "hash": "0x95c67f79fc8675367cc36ca059b408288f21ac28341a2c07840dc48ff35fc9b9",
    "sig": "0xb9d0240b4f03d212b8915aa4732332008259461b8653b94ede15fe92cfbfb94c34d8cebe0b2ca8b9b471b73cbdd54e2363ea6bb343dcb723f29af238c7e3bbbd1c",
    "quota": 1
  },
  "0xF2705Ee983F77eb8584a51e0e04fc249f7BF510C": {
    "hash": "0x7b4250c91232f26fe50f401cacd4e0eaff0d6a73c18f2c0eb76710926a75ccbd",
    "sig": "0x98d0419ba608f7991da4865fe3604b312f5321035085628bd021d2cac9994b8e3df6ff8447d1e2218e35b13425a969521df49f1f349a387d15361d982a1ca3d31c",
    "quota": 1
  },
  "0x9Ca4E61aF6bC3420C44dAb583649B036E5Dd40ba": {
    "hash": "0x1c1db96d8b81f8a44dcb5a90657beb4040aff7ec9267faadb51cdcd607d11516",
    "sig": "0xa294720ec97474a5a8fc285052ea93d8290e48f6a8bd7520ae40d41594a0697528e9c00168409f37f2d60e2f05bfd860bb087c4fec863775e466d54c19dc66ce1b",
    "quota": 1
  },
  "0x706DD8D3Ff31c9e1eE28896A44A7330F92638e42": {
    "hash": "0x66724330e75ec05839dc052beed43d712f2cd43ea4b879129f745fe3912c5770",
    "sig": "0xbb57ae6d37791f9e577655b0d01be76da5646efea01fd82744970b8fb9e5efc2792b890af9d2f6c5371478175d01c2dc595ccf72e451554ea123277df89596751c",
    "quota": 1
  },
  "0x166D978342b70C51304E578DCEfAB4588bb7FE06": {
    "hash": "0x267ff9683987167934ae6fab77260f1cccb583d1477bb8775cc1f648438b6fd2",
    "sig": "0x804f07e2db87aa23f6f0400f58f111df3b1667adc04fa99284ad5d1b14c5a0e4480dac50aa840bdddc4cb8e54815ffc3897dd7563005e05aecd1af1e939ed31e1b",
    "quota": 1
  },
  "0x6b0490408e2064fA6129624d86A64d979cd734a8": {
    "hash": "0x11c9423b1405885094f35448d74ab0799a75c5cb9ff3e38e9d7dcb00e27cca17",
    "sig": "0xfa942905f43f57044de5df33098c62536ef938b7b2af80273695d2a7ca310a346557785eca5d7edc28463849a9927c989ccba6cd67d5593fa980b3bee574f1b91c",
    "quota": 1
  },
  "0xDF7dC2BaB50e3Bb9940bc2eB429cB51006eBB5Fa": {
    "hash": "0x80dd9fec077a89b7a3d19ab5633616c47baf179f0b0bfd859d2ffb14075938af",
    "sig": "0xfa6ce3a6e50efcbfd05f9eacfe497bb9ddb68e864d42ab10c5b244be0aade4e01937b466887945b46086ff955dcaeda37e3cd48c320ee2c1d8236c92651fa6d21b",
    "quota": 1
  },
  "0x22ecdD0A4900838862fEc2cdaCf47664e730A347": {
    "hash": "0x979648fdd1fac0bffc735553dff0e38700ea1a5727b2e6ce9b9e2242c8901c24",
    "sig": "0x857cf5bdb6d049ca5bffccc02bb8c898ca87ac5f0de3b8ffdba5acbd896aaccc101450a048837a16a85e7062f27454a2516a47e6bcd6454d7b7130a36fecc2611b",
    "quota": 1
  },
  "0x53962268bC06cb7928eC5ae08b1D449c9B6c159A": {
    "hash": "0xe75d194add309bf89a5f7c84f41906685d5dc04e661e370efa7abe8c5b474047",
    "sig": "0xd96109eab0133b51fba9239a4bde16e855bd0dd2326ee0e537757fb70df42fef5f29fa4c36b27502ccf995a1d6b3de392217fb5d4e38269d0423322eaf6a67951c",
    "quota": 1
  },
  "0x47b629C0867497A869941F70bEe6cF19957cFffa": {
    "hash": "0x2a83c79c8b199fb515bbecd9ab07208291fa7eee81cce1ba3f9aa02a947da47c",
    "sig": "0xf23db9cc1f337930ef3800d73d292e9cf7141ffac23f429aaf1eda15877873027f0ecd003595b4506d6444f861734fac97289cc5a261a91c3eaf679212af43831c",
    "quota": 1
  },
  "0xb9F8cE99d32C9447Cc8E15C356488395c32E5315": {
    "hash": "0x06932dc8d8441f5871fef26911b69c115b3fdbe0527be5490bacfa97bb9ac056",
    "sig": "0x53d961a95798b9bc30c7c4d679460a61b03d98b5291c51d52cf730cf5a601291215b8483033a17fd1a8ba199440827592739d0b481ebc65c4f63790112f309af1b",
    "quota": 1
  },
  "0xAD307e6D6f4Ab29Bf906C90943Aad240E3FE52D8": {
    "hash": "0x96ac3017f0178682312cbbc5ae3beba6350e26176adcfef8d79e5408e122315e",
    "sig": "0x21259123158b9ed2e03926b06e6c75a34df82a5806d943feb82ecd816176077343b7e6f756daf3f4674cd22d924c6beccf99b8d55551c9cf0161300e9baaad261b",
    "quota": 1
  },
  "0x0AB0bb1a56FE9533240AeCa9C775D67a3c548941": {
    "hash": "0x6f0b268ad3e9931cb27378582366e5b6e6c1bfd030ed1febc3cd979c6c76fb86",
    "sig": "0x23b3992875a5ef918c474b478e12c7a91fb567ffed8f038631cd67f997a9efcd5403ccadf0aa45167d8668d891c4d97cd64b0355515adb2030c08152d809d4251b",
    "quota": 1
  },
  "0xb037C8b7B9333cd54E5D09a203F1452A9869947B": {
    "hash": "0xaacdda09b8affe0e6b24b9584c05d9970c23fec0227848279b0b037566aaa58f",
    "sig": "0x9c5ad166d42ccb04f47d442e93a9bbfd96e60b65b7653a12c79746df34179c22433c122efde5d807dfc5964a17e01edcf7368c46a986e815453d3c6c8a9524531c",
    "quota": 1
  },
  "0x12D0796A18D0264120156131D73111d33E374624": {
    "hash": "0x67d2f0426fdbe435a79653f38cb40259024ecfb20b34c711a0b204e6e69a087c",
    "sig": "0x0bde1f18200ddf736b2bd6f5ec3fb9f2ee896a533be74de8ddfdfafe2714c291782329e19279be6ff5008de134dae0469bbcafefdc30d79ec91cccf130cc4da01b",
    "quota": 1
  },
  "0x3F84F93182304057002E0782112561a67F4977A9": {
    "hash": "0x08eda079245b3d048527bd425f32754fddd38146e59bfe45d843905dcffc3109",
    "sig": "0x156ec387cb522a668c953b03552b8a2700ea38129837ac7d4366242391c74e281917faccd8405ad88493ea6e8ba5fc5e89a9530290889605510b443ac735cfb51b",
    "quota": 1
  },
  "0xD80970585128a6b55988248aCA64cce0B38aFC71": {
    "hash": "0x538775333d77610199f771439337fbe532a6360c998c81d48730ba08fdae8ba5",
    "sig": "0x49963645e49466a7fdad63482876651efc5e87a853ed7c2b5ed99ec6297e57b518011920dee50b471cb6cc99f417456860b41c0b0e4ed4d321eb06b41ed158881b",
    "quota": 1
  },
  "0x9F385449244F5184CB130Ba924cbe167A4ECbf77": {
    "hash": "0xc00306624525dbab7dcb250ba8f23ac54446d6eb54e2e5d0afe6667552c35781",
    "sig": "0x7b11668f1ad619c42571e4f520a84ba7baec2afe3b00d609116321276ba9d0073264dea6c68db84101f91479a110052666462fa60ff0e300b7bf671fe72459621c",
    "quota": 1
  },
  "0x4dD5c05ADfa186098d44813Ff251ad5cf2aF4f15": {
    "hash": "0x30ea2ca275dd2ebc76d577e8f7489dab9e24bfae02936f5a37f8019d74864a54",
    "sig": "0x8256a7dee1fc3c8df97090cf029362fdab52120950c6b578e0a71089822162125c21ae922a14f120d98f32c3795e152a4e417635b8709ce3263c530f1d94b6f31c",
    "quota": 1
  },
  "0xaD7688894c61bd431E967E1Fd37D0B2bfa629785": {
    "hash": "0x9877f057fd5ed3b46d440e7e0a07957c597b5489b16b8a0d18362e8fbc873b0b",
    "sig": "0x7e98fbe061892d1f18d650bd779a3a05fdef42393fee72ba44db49c94fa03eeb5924cab400ce1c893d3d153e0d9af7628fc427eb13316239dde9f967d55e77e81c",
    "quota": 1
  },
  "0x8A971F72284dCdd2f4852dFF358c6cd11C3D3598": {
    "hash": "0x8d3229334f4d41ab2410da374fe6201786ee898b1cd8a98d819315772549d61d",
    "sig": "0x179d7ff503c7810c0ba638c81632fa8302fadab0b6ad571cb87fff1740e365a659c3754502ef8ed2d95b3fe3b674d82456f203e1667a5cac6c566c2b4a3806d41c",
    "quota": 1
  },
  "0xBFe37A45B9DDd6ff170FF15090d35BFE6eCCb475": {
    "hash": "0xbf6fee829d59c23b39605100868997ad23c0e834a43634413492a568bfdbb7e6",
    "sig": "0x561d26de1abcc84c2ac409faad419b98d381e158ba60432042ea7b6dcdb668e97b3ad4a12a8c82c9d24a4a788eb8589cfadf3478390ec19787b37d1adb2982851c",
    "quota": 1
  },
  "0x2F08EA7Bb0f4b13AF7ce135f73CbfA3eDA567B13": {
    "hash": "0x834070b749a79d8317fd92285ffd7e62534bbeba7edb2104c6ebeaf408797115",
    "sig": "0xddaace48f384dd4bc53cce17b3c387eef4066c65bcc3764b9d2f64adbb60164c3ac45a37e8f7e56b25bb77a8c006d2ca981de7915c06cde79905812471274ec51c",
    "quota": 1
  },
  "0xf64d6a6C1966Ff89aDb2fd406e1D78611e289501": {
    "hash": "0x672068555216cedfae5a894d3f5ee0a49c63161f46876c481a4ece64ecc6675f",
    "sig": "0xf5a8d643c0f2cd5636e1215192e5a729745396b67f3d790d727550ab71ce80b42677d03f31264528649fd92ed2b4b1ddd3d3219a1615fcf57f6577c57bb1398e1b",
    "quota": 1
  },
  "0x17763987F2F2D7D374c10ADAd7e1860a489E5552": {
    "hash": "0x7f44e56f6f65a759832dbd924b87549b3d68d9de2d88968fde4cee4af69d0d81",
    "sig": "0x1c4cffd6406dbad6ea71fa2c85ba3bba35dc2455f1a1daf55a9a4c91f464ac19439d767f1c7d01592998f50b371c7589f1ead9922bcf1edd2eab2a12953628cf1b",
    "quota": 1
  },
  "0xB579293eC574142f45bE6f5a0Bb0e71800e060C3": {
    "hash": "0x2e23069c0c6efeaf0d7e44638810c0e44ec7ec77668a7ef68be587c37d3a93bc",
    "sig": "0xc0e5cd05c7422cda07c69dd04943221c295d2f01d6506a86f11505d44144715e5f68a42f26d00accb0d629b7404c63383c5555ce0dc5468ff4474d39a4da75101c",
    "quota": 1
  },
  "0xF20b0673718bF4DF7E1132eb6bDAaDc7330A20Fe": {
    "hash": "0x5e1abab678b26386ee2731b552b3bbac3d7b64f0bedbf529f5d4bfe17fb247ef",
    "sig": "0x238a0778d30b3358228d4f2a45742cdffadc1575e7cbdf37b9329657375ad8246f2b912c557b0bff0327d30b172200b2192a57863d73cfd929c0d9dbc26bc7271b",
    "quota": 1
  },
  "0xAdF9b5271E30fD8aA0019Bb3858E448dd1E7b29c": {
    "hash": "0x96fc1a63e8b6053eed2e61e4e02a82b28fb736ff2f0ac360ee781ac69700f060",
    "sig": "0xfc0ac1300f92cdc5c11cb69c75a1ce4fa3fe34090dc59e1851127c2d8294957624010c5c89b0094e3ac929be40e300738a5290d4fd976f3889496adad0106b151b",
    "quota": 1
  },
  "0x5f0521C2A52E896069Cc3e6B9DEd0E3390744607": {
    "hash": "0x680d0e3bc0627a5815393f75b2906a780ce6445db89e305093e91c4ba147ebea",
    "sig": "0x37607382649f52687880413513c703df3e523bed72529b59ebf4a5baf08116e060936fc524750ac3544697f2d9a34798718c2cc157919646b5f6fe5e13fd95ed1b",
    "quota": 1
  },
  "0xF6f85eD6Bd079Fd83790B23602B2bAAF45E21Ae6": {
    "hash": "0x95999048eda7378c86bedb6e9ff1e806c6a802d27fa1aba00c1511426ff34251",
    "sig": "0x607c6cd14d2cd709dbac84692406e94820c5e2113d60320e1ac7efc17b48c55d09647007191e1add534ab8682785aae7a858d9930d0eb38108a487ec8b2fded71b",
    "quota": 1
  },
  "0x1e942B375756E3ECef4a3D0B5C68494FE3a9306b": {
    "hash": "0xb5b612abeeb31ebce95be28bee602da74d6c3c2bbea5c3d5f80193e79a92bbc9",
    "sig": "0x1e456ae2554130c8d49fdd57cdf3190f1f0486cd24a65706c43c0a9694d57fa00d70bf9dc717bb44d9fa3d105b849edef6d1c94d44740ab3454e361392b188df1c",
    "quota": 1
  },
  "0xfDeFE41924e96Ea9077032351491180BE078Ec59": {
    "hash": "0x2bd2baae1d53900fa9aab2b31dd765aa7cdc28c515da178bc683e50670078f90",
    "sig": "0x55b8efc4e572c3cbf7adc6b6e2fea8a776eb69f8062557182559dcfb854d81295f6db4d8351efd30298a4224bc130cdabae7b6c871cb8c32914c530e795dad9d1b",
    "quota": 1
  },
  "0x55101F711807279aB94E67681dBb2A86beadB61A": {
    "hash": "0x4b4fd55c080808061b0ca4eb23bac7bfea012ba39fa0dfa21abb66513e2ccd46",
    "sig": "0xccd5e145dd3e636a0466019d2d539ee6e3e7eba15f37cb7ddaf4fe9a4c2769417ed85fcecf044def97707305fa9beeb35abb0aecd0064e08991a33b418ce47ee1c",
    "quota": 1
  },
  "0x258F076e94AE49A656EB1B79Fc7CE4f392442a60": {
    "hash": "0x50d83078df01094fd37a25bd998680e11fe0a7addeab9c269be184da8fb309ab",
    "sig": "0xdb356cd00c13e7b7a3b808efad5ce339b7841abe40f6fcd95c7b76a425f598cf64c003f7798c00c473cab2e8e93c32100854b40a4ab8a98883863500901992f01b",
    "quota": 1
  },
  "0x2400Db2AC9BE80b7E84F9E77423589F5ce1Ae68D": {
    "hash": "0x30df3b4973d304a1371416cdd05a8d590050855685e4a783738273069357d6be",
    "sig": "0xe1b2626b161cfff463c7918a7285e4bbcb37bc24048670b9656259e2ea460d5e24b934e277277a00adb59aa9d0996dec5ae85ee1c0c2a3a748d8bcd44349829f1c",
    "quota": 1
  },
  "0x363F725beb64d679f04213774F27CaE4689b292c": {
    "hash": "0x2e6e3c267eef7582454fe3a65834519cd48f18221ccd6d9e5e44d79dec250068",
    "sig": "0xa43cb24149cdd6f022b43024511e520259930c884845d645746104f0c102b11328f71a190c853129d13e892499ef73bb21b37ba586c9037f199ebd4b8f6ed25d1b",
    "quota": 1
  },
  "0x85c0c09570C955F6e07522E9ad6041716CbBb7fE": {
    "hash": "0x5298cab869923e8c09d3cb6006a0b132b60a06ceeea419ec6006d7ffec442434",
    "sig": "0x2f965040e9b32ba29439db68714219fc106f2ccc500fa7433ca5605d92c122342cf2267e90f9b5f9eb21347259f28d986261dc44a556ee1e0d404f8dfe42e77c1c",
    "quota": 1
  },
  "0x4F56ad5Ef0206467A9DE8f0355B4FDFAc22865F9": {
    "hash": "0xa26c552377062d30614a992df4d5452301ebbb774f5b4ef6055593248f868fae",
    "sig": "0x5f88d3fe399cb8592af37f7153852013bcf127ab217660791549f455c00960fe3b92618056ce37a50e5520b6224c9698d4b3fa708219079f5a09b3cd7616154b1b",
    "quota": 1
  },
  "0xd8BcBC5E79e42bEE67465c340bc8EE2AAe16514D": {
    "hash": "0xfe592ae73d101b44f5c894b758c5f590e0f7edfe660e046986508f1518b7d87f",
    "sig": "0xf736e9d4f02669765c828e877f138309f64c32623012c3402b8face3a0f0871b00ecbda17c56012b395690023186623cab427e8eca57aa090913dd0d651764821b",
    "quota": 1
  },
  "0x7eE6B67e16db17157240b2e43010661E458635B0": {
    "hash": "0x327c57b29be6d792b3e8a0cefb66f65729a58c09f43555e97ceec9f90c43786a",
    "sig": "0x27d959ba4b9455d494244349cb4aed4a78b8657df80c9ace946cf145b7e36ff371bd7800b286b7cca8aa4bd08fe5abd1f6f849fd3ccd926e66bf9db0f02426091c",
    "quota": 1
  },
  "0x8c3f2FDaA32dE9A328D2F920E214d74Fe9AD390f": {
    "hash": "0xf0556031be63b514ff7a6a387a48f67bd6f8659b5eeae1e0e0b4f491d83fa689",
    "sig": "0xaff77185f1539fd1e12880d5199d354764526438e772a6b87c1871ac2334133a045bfb80867635abbc5906891a63118ed8c2ad2e36141f50fd8987617e2699c31b",
    "quota": 1
  },
  "0xe9945b3AcbDc2f9dcad8871F01aa9dabBb20d882": {
    "hash": "0x3ddfee73ab3ee9dd312b0a86e5b82eacef3f93092c3ee1bb0793d4a28f8a6e12",
    "sig": "0x618fc6cc0c416861bfb4a59b42fe1dd4abdc983b9e0ac65e0499c24a95e6d0656ce8e1b6fd62dba262e658b7c6cd31c0c5f24d9634c05cc70abe8bb3b8a16c221c",
    "quota": 1
  },
  "0xB021606639AA9Ba79bCd27861a3B2a65b7540374": {
    "hash": "0xca26ea715c326480b67520eba1db1dfd96e57f098960b399683c47392fa87e34",
    "sig": "0x8dcdeb1901d90a8369b64385f02fdf295f8624a49754be20286d8a75cb40ab0136054948195ea93e0f916a418a80bdeae0975ad5553ac679b40ad3a578681a291b",
    "quota": 1
  },
  "0x183bDB344A07Ee3D27f07AC4799A56E4A2fE5439": {
    "hash": "0xc84f4399bf4a746e60f208a3d43afddde87cfe7cf078e2a4cb4bc10b3d264591",
    "sig": "0x526ed0b5ba37c2bf5316afb3d071929703612cbd35f76c48b8cffcdd92be044b0be181f146805a331cb0a3859ab5b2b3507109de91491122bcbb23ff314a74801b",
    "quota": 1
  },
  "0x928aAe68FEc1B3AdF6354CbE4dcFB3F4b42aEa08": {
    "hash": "0xad513b8d2e811014b6c1c5ed89529aaef947a571f4ec2de4da38ad1d8859eeb3",
    "sig": "0x1b0f8516a9e8c3f7669c53bef1bc968888c8c8f8b7edd463ba11bd182cf269c448deba09b537194e50a3e680fa1e8be4e5d141c74f86dfd8297ab3f75cceca251b",
    "quota": 1
  },
  "0xF814a8e1607Aa1d1a33F42a4c9B3b67eAdCE45bD": {
    "hash": "0xc24cba0a916f9ee347a0d10ddd081be786a82386e73e21df72f7ddeae2892a52",
    "sig": "0x24b5a928400322a516893e0e3f4e9993b75acbc03a8f3122c54447f70fd13b9e37b547d5f065c5d52280568dafba8f5672af9beafbcfe6ad74bcccec8c3564671c",
    "quota": 1
  },
  "0x76B953EbDC759500d489eb818eB7d6894911885f": {
    "hash": "0x57d8b79db4adeedf067af1c5975f07d8f051fb589b81e64ca390184effe227e7",
    "sig": "0x9ede87ee25ff4c6302a55ed7b49524403b15e195f27926147d950cd7aebdb18929db376db17e449863b9b515962167c64311c9d44be02b519d0a12b7d0c6335b1c",
    "quota": 1
  },
  "0x8FC4faCB1cd42A80A2b283bC58e2665788B8a0fb": {
    "hash": "0x1295b6e552bb6e029837077b88a4ea35770461223a0442a826c8d03b988176a5",
    "sig": "0x630ad054606ab9f9dcd76cec6fc40157843200ccfc674eb2be64a238f73f2c636007c9eb3ce123619c881f678287146f43e38f8304d7fcefd6d02121528786361c",
    "quota": 1
  },
  "0x352495A5b372327D66db05465a135aFbb0ce5668": {
    "hash": "0x19872b05c5f85bbd760f2487485eed859156debaa8dbac062efb491216d2e0b1",
    "sig": "0xf81b20ac5bad72cb310d575aaf8893bab97bd27dad4ae5cb72002569c15560a41f8dd2669565e639a19aa6725b8846181dfb6eb2450ddc1dccfe21a761bf24581b",
    "quota": 1
  },
  "0xDa74505474d4Dc278Aad72dc89884344AD580807": {
    "hash": "0x008dfbc6bd6279341d3d4a5a857ed56d3c543394c235d8f5ff9a1c802a21205c",
    "sig": "0x5ce24f4e4b5f590bd832964d57e6fb575c1b8b2f1cfcfb5350561690fda7f39b1b622e25f03d49718d2c2e5e9201fc034172181db1fcf5a6d84cf7ce9eb6e44d1c",
    "quota": 1
  },
  "0x801B02292Ea208310E56B00eB6C566687ef911e8": {
    "hash": "0x54e48c7a0f9e6ad29205c32cc82c0c157a4d6c5bb34335da2eb2937435cfc9b2",
    "sig": "0x798ec1c3d0b84fd08a838c1e48635eb874a2910faed3d43702c360beac6475ba0722c25985e98dd5273443e084ee656280e3c27d069f95f66e531356bcfd14f21b",
    "quota": 1
  },
  "0xF694ffA78947d399eedF26855B8D2463ce8F8C81": {
    "hash": "0x21a4a1ee0f36b17b6e6b2e3038e3fcc9224c2c14351849f2685b7bf549b36d72",
    "sig": "0xf9cefd326d7edaf72385c08e3140d4222efc25fd5012b5501917d9a8f1be50152bc9bfce1004ee3a674e71f7d379a6037d5f4320bf957445328cb724a535f2691b",
    "quota": 1
  },
  "0xAe19f0Ef64D17138EE25Dd5A2385Ba67cc9f2097": {
    "hash": "0x403a26d64b4a8458aa31768f159145f7d2d8b660305f844f3bb6d810741f39aa",
    "sig": "0x0f8049d3364a82a0b4f3648273750aecf0e594efe57148f9a949297ea21c8074538d4894c04b77c1281c0d3521d3e6c94b0f209ff3a5a6508ffea10f7e2cc7451c",
    "quota": 1
  },
  "0x6A31b91aD87538b898e69B8b230955c4f76c166a": {
    "hash": "0x9782291642a29fde2c9620aa437e27f032c4ecf4f3b879a9f80ea544c86d5284",
    "sig": "0x9373f36ad730da7812b7bd207de41d4e2c65f6718dcf1a907d17cc0cb04d0dfb16d7df8626d991ca852493bc56fd7fb3e8af10fca5d36ac7634d9b4bad8549e71b",
    "quota": 1
  },
  "0x797520f471dE8eC7b1e771e014149c4fD408367c": {
    "hash": "0x0dce2a0f69230db13a86fbc06087e439e65b117b4a3f4176789187862644fbb9",
    "sig": "0x921b96e689237efd80634e3ce9ac0e0052afd9371341a6675832eb6304fd0b936ef447f6f7a4c439dedc6dcca083a73232ac4190d8e4fb50718ed29662e8668c1b",
    "quota": 1
  },
  "0xE1365dDaDfeFE58A1B82605eA0f1c6c647D957F7": {
    "hash": "0xad48bea2da941d51c0722f5c0596894449712d16bf3886e73926e97fa52febe9",
    "sig": "0x1dde07361e72b24f0c8255dcf98135960c13be37458f78354ce908f31df405df4ee1e945f21282d3a30479e132e809c36dc992955aa343df4bee01b41fca5e9d1c",
    "quota": 1
  },
  "0x1805Ac17c50C71c916Ec8937f6C5b28ed587A35B": {
    "hash": "0x0787362ee313900ac0184d8a728a5741d1ea484e3e6e2340e11d884b728f68c9",
    "sig": "0xb5b0143a271a524635c5035c388cd8b51d605a3f64568936d388c93271ee544475bb83d3ca7939eb61ac5979af02939ac2256f10575dec675a72f3d275bc45e51b",
    "quota": 1
  },
  "0xfBb0085a564daB59C7F0A4bdc23723dD86865be5": {
    "hash": "0x46ff43f07e8f7e8d0ef9b361d6f516eb3ca235a448c2c6b9c5114e1fcda2b5ca",
    "sig": "0x7e6aab5610606db357dd77089b4b1071f2474a37e747231133bdd3b5dd62208304018455b1feba3ed478db73c1c3a99bba7d83e90ea36a95d14ea3b29a9ef03c1b",
    "quota": 1
  },
  "0x3b4D0009769A74c06E4bf7E3933b58092Eb2379b": {
    "hash": "0x392ed9f24a7ecd76a66e36755abebfa7ae2636d4df9495353cd49c1d011a0eb0",
    "sig": "0x2973291875dcd267d2f39ff1b1e05afad81b90e57c41155bee01b30916204f0540d5914a22e693e669c6297662227613a4823aa63a4aec5dee45ca0840d2ddec1c",
    "quota": 1
  },
  "0x1f1908C0d3F5C218032b928cA6D4Fdd5109da0A3": {
    "hash": "0x36a7f07048b7cacdc67c92124748d1e04918cec2afcf5bc6aa97aa51b3f9ac9f",
    "sig": "0x5bf6fff4769fc7180f314e551fcea90e3a4434c44ca68e748848258a177665335204dd14a0b06c189973d5a9e42bb2c61569a652b366cc456da1cf40897581f81b",
    "quota": 1
  },
  "0xe2B1a1bbce7065744410d6E1bd2dDC09239d58C7": {
    "hash": "0xe99e0ca0edb219565a7a0c87eaff8f0ef73e0b9599cfa4d0383a601c22d027a9",
    "sig": "0xb3b3da926c73ae5bdaf1af72503221a9c550ad50284b07fd3f8991ede3e933574538913eabbf15b3aec03224ef71a38526e9fb802dbebddcfd66ab154a3950761b",
    "quota": 1
  },
  "0xB98CEf3bF4c3D03625a05081dc9655e6901D6e01": {
    "hash": "0x70e9be8283d8aedc3e706b36484a729857be2d2f67497d0c82370ec934b2cab0",
    "sig": "0x592bcd4884207f3772a8a63d3eb4f9bed015db85899dc96b46f2db454db3d13a159263b2460c6fcd0337d95c5e766f2b8d8cfbec5c43702777114d16b724b67e1c",
    "quota": 1
  },
  "0xad8F72f43F893F48ed97f18E8DBbB0BB90D3bb66": {
    "hash": "0x935ad0ed1a0833474628279b81086db5b970edc31213f9da757bba702c1fc678",
    "sig": "0x3341661c9b3f80eb06fa7cf1ee9b04cc36ae57cc117a1c024de1edc2f4a771894dc6a1f7760d5c317975263d4eb41be8807e175f0b8dab3c659692381f1e89361b",
    "quota": 1
  },
  "0x2A1BBcdff7A047d82fc8829FAA0D13a8D2cf1dBE": {
    "hash": "0x913e887b59077929afefed9053d3df872a6d07ab181ec87186f3f6e216621684",
    "sig": "0xde990074ed4b4b5c5373b923ee7aea74e36c2e3d0a5555b1aee53181b1d43ba8271df2739d38b1d4add805c4060aae4f1ce020bf7589d39a8cc9b09ccae605801b",
    "quota": 1
  },
  "0xC6E3316aE06905c2Fe52a9A3c9B80f120bf62FF2": {
    "hash": "0xe4517909c51fd0352598abb5201b4bd919b4d548a035cd6048068d12c824bf51",
    "sig": "0x5f349f75dff515fd306012c062e8ec76ea4d9213e146dbf9109600aacb039d40339a6710512174fc8b682883a0efc2850bcd873a15d69a36224b8528f08a76ca1b",
    "quota": 1
  },
  "0xBb3Fed02EFCa8f377F986567A2285232E1F52922": {
    "hash": "0x78c1e3e76b68b60f02be83847d36fdfe66cba322bbb24581094eed019d4f2d7c",
    "sig": "0xd5f0c6f0dbeefd41a7d033bab1bfab96c946ba97059a8257ef0295e9b9adcb475a4bbe42188969bea8ce4d07803ad3292deb5eebfd8ba69e89bce53eb8a028fa1c",
    "quota": 1
  },
  "0xe3f340b9a482bbb4c4eE519d6DA90a70A6E973E2": {
    "hash": "0x21a1b7177ac26aba7c4546d805dbfd3128f3946dd21823478ccf0cf952d6f27e",
    "sig": "0x623d2a238d697749d2ee1dbe1a56f59a37efa677457f6aabf06726831255490b52d575c02aa3441de79b397525bce85a7c990b168a430d136e90b25a88c6d66e1b",
    "quota": 1
  },
  "0xbAda866138b28B8CCC1D1E8d39776850c1Ad6446": {
    "hash": "0x496cf1f707041d3fcc63e666c5d27fb4cb2fdf507316bf62118384078199cfd7",
    "sig": "0x30854112c8df5a8da2e6a0f2f4ef28d47eda7aa9791bf23486303aa2e54f1a21446d9c3c1e3f3a7c5540cac9505c1472fab4cd96e70deb5c824912be8a3092191b",
    "quota": 1
  },
  "0xd0Ed44cAE30E44F7937726C392aDF10760B5bFec": {
    "hash": "0xcb45f69dd5b1f843413ac193e9976a0581b4dcdb734e7c0fe93e0c14a0bd2f63",
    "sig": "0xefdaf1ab1b96c91f392731d0243f7a205e78b84e9515e31784dd8bcb09a6a4da698b84414f988d0c6cbf95b54677f6227b4550aa1537822578f9a1f8e1cb5ce51b",
    "quota": 1
  },
  "0x0dE45730976BCfE8672d6A05aA704eC99525D8f5": {
    "hash": "0xe94cd90eb7fd2619949fb6c2b6698505f7985a22d15ce282ad281cb2c480de48",
    "sig": "0x22b14107e1be86c7f0871086b26290cd6174c30c834a508f7ac21b7bca29cb5f42dccf888547cc8256f142e2825811a59c53ab2cd0f2e09424267cb0486f17691c",
    "quota": 1
  },
  "0x41c5650491F981bA3a1354C516a693C36301a83F": {
    "hash": "0x824627d251b74d9f26826ee94a5ad7bc8a67a7c41f5d66e3691aa75adab342c5",
    "sig": "0x50a1494068401e8c795e096f73c8acdbe02e8e45c35114dd9c2496fcfb8f1dd13732d121b2238c0519d60cca348b79c6b187e56e037e299b0de74187b310782e1c",
    "quota": 1
  },
  "0xbA10effcF21b34e953aC6f7774653a0593a440B1": {
    "hash": "0x174d21fcc2709fa18f1d401077431ed8d9e9bf9da947a6ca1a4e95a928d6c520",
    "sig": "0x9d7f9c87cb7e64a42eaeb9a5a66b29264880c4569853aa5e854fa6caf55860ab3b09be964a7e4c91cd4dc96ba499bc96295914043c8c6c1269852d50d31998971c",
    "quota": 1
  },
  "0x09EcE228f852dC9EC1dc330CF47F50849CbbbDD4": {
    "hash": "0x8e8c22ba5d91c535fcefe8a51d569c9c03bf68a083945191f2dd7d175413d231",
    "sig": "0x3608df74ecc05922dda48594ace0148d96442b9f6f0046272d9543767d6b51722dbc6bbac2933352cc786cb94b959eee421573524941cb9886b01c46043c0dbd1c",
    "quota": 1
  },
  "0x1A32c6628ddAd52d66675DE6F9e0ABEB57a2e71B": {
    "hash": "0x7bab5348104523a20aed4154dc092cfc89ad969d08b1d4269f1dd0783e2ef4e5",
    "sig": "0x16cd8489b9ae2dc5f454ea3e710b1b677044171b9ac8b0c8bab6a52aa3287d3b177c07a961b565e6c388a592b1984232e0a6116c5e2fa8a79e40cb77ae4161e51b",
    "quota": 1
  },
  "0x318E1C09075AAC6c1D27b47AC199DdeACE6ec137": {
    "hash": "0xca8ea8aa8dbacc063f9141b42c2e8f2f54458fa5f509258029a27eb830d6bbb4",
    "sig": "0xdccdee172026fae6b423f9aa0b6cc037fb20aa36f8d10caa315394f29d59aa7e736b2ffcf6787d3daf4a157275475a3e0d0691e799ccaca150b8eaa0ac8892c31b",
    "quota": 1
  },
  "0xE7cE2Bb1E1Fc77F4f654885F6b2496BEb19ed093": {
    "hash": "0x37926d45930791ccfbd9ad63fcc10d1b7da6ff6f8df3647fba6ebb0975c35640",
    "sig": "0x6c096271cc6805737969ddf612deabfb5069856d9fac7dc18f3703c5d7099e2821e712f868089449dce11f9a2b6f989537a12c930d5eb60ff281662c353ef4c91c",
    "quota": 1
  },
  "0x3bBBcbc1c0b584F61ED746096E7b67c6BC2DAc93": {
    "hash": "0x1a09bfc02480f4c0666b36632afb3e22c73dcc8972e7108ac885873d2ef190c7",
    "sig": "0x9052813e4493511282a0575d2b5a7b58a966b96df48ebf189b9d97ad017385891d11839135e8a1c6a99cbc7de7b99963e7270cf7b303a0fbeaab67a97efdabae1b",
    "quota": 1
  },
  "0x73BEaA2Dd24aDA65DF6Da284729558aFDd8105Ba": {
    "hash": "0x286eab1a66e1059f5738b57b649bc0841287b1210f45578361e5df76c319e4d3",
    "sig": "0x4d383a673f6c516b15a223863f15f2df07204ffed8df4fb0373ce1e7ecad3a464d749f8d700b6970c4f730c0263aaa61389fc512865e14d80db5a1bdfe2d10df1b",
    "quota": 1
  },
  "0x89c927ce464C95612059C84e5fd0eA0DD7955237": {
    "hash": "0x3cf616afbb4b852246c7244953cc9d25038d52ba20bccdaf2de5b3ea6d932d55",
    "sig": "0x8daee191470644ed74df98328e738225da642e33b60fb506e96c764e39c2405727dad7dc1f6d8ca1dd3eb2db51a65ec4b4d441d9d33b24d0c6fc7d547a28ab831b",
    "quota": 1
  },
  "0xd9bA71CB3D489E64E94E44134eF5a3c88106892b": {
    "hash": "0xd01821cc060edf30b0226a610f8767ae218e81888dd49901411e579cb9b7f606",
    "sig": "0x0406f6f16aef0841f217063ca3ba31a1fa6e0efd32166080045f8fe9d67a3e051525f9d91a0818d2e84c7a5162d347b1aff6ef14391ba91377f2cccbdd0fd4691b",
    "quota": 1
  },
  "0x0ab97812997c4e8610007B6F368dB17ffAdC32ad": {
    "hash": "0x841df0eb302a1bc5c8d1f12418aa4ce83bca0c0fab3d4966070b8e2e3f87b62d",
    "sig": "0x56cc75054e36c72b7e91e7cdda929016966b5b1066c35c4edf9c92b4fbf2f0a74a14ae225dce18aaa08186e728f9314d5ddc62f324b3f97257fa270c39144d1d1c",
    "quota": 1
  },
  "0xA54c8622D10c9ab06C229E3A2f154add2A1Aac53": {
    "hash": "0x26fe9d1c80f40e24e341aeac0cf8b5e78dcde71c26a379244801cd4baa7932f5",
    "sig": "0x560d1ca3c3606b5d11d28a6dc4ea4752be58c0e8db0216b2f36d9bae5f0407ba7c962d4c03e32b31e4f0bec38ce2a9ec1a94b78786c1749a59ab7bf62dbdf1951b",
    "quota": 1
  },
  "0xD8F569C21f833E82749704b0A54C7666A02487a5": {
    "hash": "0x5a64ed8e9fa3f413a18f22c7f88609fdfee60e041522eb698dc65959ba77e7f6",
    "sig": "0x7d2208754934a5ac3269e0222e1e312be2a8e874f68e39f9f6aa13db59f1f5620fb929bd9453fdb94ce7b3b6ac08e819a53b4a04213ad11670ceedd5c59c0b3c1b",
    "quota": 1
  },
  "0x4808fd40E3A5C30f0B2aef4aE7591bb3e1248Dff": {
    "hash": "0xe49c9d89b17a6063fac7c6eb63a4df76eef0172f7c5ca49dcdbb435bb0f542f6",
    "sig": "0xb119c5c493b65b0d3515ab7ada67d12dcaaf347c1d0108b094ae19a3c4b84f99438e181f7aa86da519882e1676d98ae81585414456447e671dba851882cbf6461b",
    "quota": 1
  },
  "0x1d4E9171A2c4A464ad52594568888E708EB53C8B": {
    "hash": "0xcf28582baa7efbba620a47e51d7d2f4ca47ce8071580dd189362e549e6d03ed6",
    "sig": "0xb58b85fae2a0d24e62b13b0002d8e579e2d2ef428428978a6563c32005bb283e03ee35e61dde1201177ae6ea9c2ca0b31a81e4478df87a0dbab57a621abca2eb1c",
    "quota": 1
  },
  "0xd662fA474C0A1346a26374bb4581D1F6D3Fb2d94": {
    "hash": "0x0fa32b3c23b830bd19738ada59518e0ab56ef499f7544792584179ae620b233a",
    "sig": "0xdb9b123b11dc489d1f4e4835832fff30382053ff4aaeec48017c157d865374743a7b943b3a41d3b392d2b8f5a702844acdf4b58ed2b60382a718419893eb433c1c",
    "quota": 1
  },
  "0xaDb4AcF73f9773C0B696995f05861400125278D1": {
    "hash": "0x3cc11d60ee27d2a38f84d9bf19cafd6a2c57bc77c2df9870424231e77f02a613",
    "sig": "0x73548a29b075c94132bda56ccda7fea3579a8df413b01c0f179eabe2a837983443d33690929617758ea5a19f5012615c37f9668e0e5ee15699857783dc21eca81b",
    "quota": 1
  },
  "0x89DF1AF8600af226Df87D01ce4eee7E5c0Cc1642": {
    "hash": "0x66b6ff548b15a57155df43d89e63852339b84673a583916fd227b4dd03070a86",
    "sig": "0x3ba64cc8455ee87b42c03035b9ba2b72d3e24300ef164ddfdb45a0347b93ecc941b483890c1614a9037097a7fe09c10b17518acabad8612fcb0ceb726e2dcf3f1b",
    "quota": 1
  },
  "0x32CcaF20c89e76Cb3f0e35276532fc4e9976fD10": {
    "hash": "0x24c5af29e6b5eae9fc659d71ccfc522a678c2ea3efe48a032c41763956717040",
    "sig": "0x6b6890ce1582fe63bad9fe991661dccc14b9f630640b771cafb97aca9460d43925934d8f562cc492ad0c19862494f06792f0b0f0539c4546b7bac941160042521b",
    "quota": 5
  },
  "0x1Af4f1b50DA0Ceb170717A5e63670B4183c43E80": {
    "hash": "0xaf0e5d8b9b2e89f6516bf5775c8c4c34a864ea18cb4a2fdd6e42eb88db5a52f7",
    "sig": "0xf2441de69f1a922758419a78cd796ecdc0704845de42b480274f3b3bce2d9bf0024d0fddd21a38a9df363fee432f970eef7cd5e106a1477a3c06163dad4a25081c",
    "quota": 1
  },
  "0x084405086372C3924cD7663544433a81319f8B91": {
    "hash": "0x4b79785ec7be903ceafd3c24fa436da033e806793f892826a3fe76e800786d85",
    "sig": "0xa674daf6a0ae08eaa4d1e0e4be315a412ddbff292cad7437226d51fc7a37037e0e348d84d367d43d6058ce52610cea8bf1f510199e93e1697f69b7a0829528dc1b",
    "quota": 1
  },
  "0x7A6108B9522542B44def9E3D2139ac0E6C342F6F": {
    "hash": "0x42679aa53f38bf8d51a46200c32de1373dc5dfe5f9dbb18126dd3ab5f921db5e",
    "sig": "0x1e16d36b585041c8404c49b8fc8716ef0ab0d7b7ecf160ab99094f5600f04ad304662071f118273f1ecf6ccac07df96fbd10eda79790ac2c76a3f6cf438b4ce71b",
    "quota": 1
  },
  "0x2Bf034ecCEbc8CD60Dab9c249b6c2996Dcb7D8EC": {
    "hash": "0x32ad243341a0e327f4e02921a883837a51a34377bce6e669bdb8741ff80d86f1",
    "sig": "0xaff2ef9b77b8a71c1984185210a1a58721358f431f0e57fe1bed352a13b5bf9d481e790245721fa2451404c105b12b584238ab0862ceef316d9aa860814ee1c71b",
    "quota": 1
  },
  "0x959491075124B4CC7f262582eBd581618E32b39D": {
    "hash": "0x0778c6a98d37f6200c2d64826857b9a23698beee9d9fae17e257893df8d673a1",
    "sig": "0xa5a64e2206b2f40c22ba764ebec7230e722560f3f8417e66a8c7776d97557df7119f482740fc1ee1892f547d89eb0e1e5188c14a2b1193d3d1cba700ce0b4fcb1b",
    "quota": 1
  },
  "0x7507A0790ea5027E26B0219a5fC8A85343CCdB94": {
    "hash": "0x660a5ddb2e8d1774105203550d54224e59a8b27adfbffe10d2355157ed2f3442",
    "sig": "0x6109bf06c23db31dfc595e0b9bc76fda087ed9c2ac3bf12a28491ed8e8c799c860d7c2b3533543951707c9e2597e2d38277a17dd3c3b55e2b7ea355673808a3a1c",
    "quota": 1
  },
  "0x55E2456A26Bda16a97A271fecA6840bFd17b1dae": {
    "hash": "0xae919f983ac18dceb123963b60f299af57d289f2ec2e536ebaf83db782c8ee47",
    "sig": "0xdc0185ef6e3bea0f86154dbafed758c3316d7c46f05beba6feda29a2930b1cf74d27b6531bfb2e136500ab19513bc7997078abe8a29023f99f9ecc281eaaac981c",
    "quota": 1
  },
  "0xb11F61981882310Da540889e22f93551318A9020": {
    "hash": "0x9f6b426e9fab01031a7c445deaf4b909082ea49fc19fb7d2482f9a21f9e3c96c",
    "sig": "0x57d0fab4951cea34689e8c48df520cb31379b6f4a61b665013d4c4d153e967276ef3ba1a4ef4362c29f43aa9d96959ce00c3cf5c5471e15f0f6d722aa26561001c",
    "quota": 1
  },
  "0x36E626fb225F431146ac7A020F884B014F584700": {
    "hash": "0x38206016ea441ec097681071f1d339c366ec608cce0def0f92502b2a6b5c0362",
    "sig": "0x37b70a09c1dcbb652d7f82ae681d03e9f2a46530b7f2624d5eb30b6aa019e808057f5a462f53e1e2cc44b3e06e0d8f47e6fbfb10aa642f8be14c30956dce0add1b",
    "quota": 1
  },
  "0x086E5DE2076979A9b2BE0acC696f18335c1A11f4": {
    "hash": "0x41f3af910449d7dabf01479124e95406290a30255dd78c1715f2966a30791ca9",
    "sig": "0x7246084af375208ecdc5e4bc75e6619a83617f95f1190b3f5cf49db67f0f144c07870030e849f9422ec0adb631a578139eb45ab0289af35542164a8e737920ea1c",
    "quota": 1
  },
  "0x4Fc40CE536b9bA4E319C9D08776F1e83000a379F": {
    "hash": "0x6cfc0a6dbdf494cbde07b02452149599d911af270a82ab10fe435d2ea5752805",
    "sig": "0xcba6d767db5bcf27c34781a13b69de1fa363446a5eba09212919b61ea521fdb0491ac5aa05767b25a916ca0f9aa1e078f092139ea3857d8882b727c3c714abf01b",
    "quota": 1
  },
  "0xA7FE44C2d8114b0aBa3A285e63f1942cf2D53690": {
    "hash": "0xc57b653889d9e00aa0771c2b79da6fc90a649c94ad8c68e0ab4b90273af851dd",
    "sig": "0x8849eddee6562115c597b012bb0d3c819d994073b233414db1d0510b3d78857743c1704f3b9bf4d084cef2c5aa7d4007dd54befa42c0e1eed272d45a19b84ef61c",
    "quota": 1
  },
  "0xefee858e5EedEb761dd069428E85fF18357760CF": {
    "hash": "0x7b357bb7e8bd18b1b81b9acf5d319902af582f3a57bb87f4925dbead56faaf38",
    "sig": "0x5968b48a05055491b2e63fc77ab4ec72f1a5b8580f09ddfb3b28d6bbd172322a2ae9fd9f36bb7f565cc0920a9516194837301035c3ceb6dcd98951fba13cc30c1b",
    "quota": 1
  },
  "0x66Ccd978b61fA3aB1584d787d62AF025BFb671cf": {
    "hash": "0x0ff6207953f3b63f3d5aa1af47a908c9bc8ac739d498eb51508db34029d13837",
    "sig": "0xda139a6692902bf158c7d7f220ca527432bc4b5dcc7f060280eb0c63210052435bdad15ffd6cea45297b96a554483170e83194696d6616b1901af5d81ec043f41c",
    "quota": 1
  },
  "0xeeDE35ab2223f1341092faFFA7a09Ee4e7D80552": {
    "hash": "0xab1d76f66d4d2d838786e1decfd17ae731429f5958f8de4341096fde37694902",
    "sig": "0x0b7cc041f04966e6bc0dc57557544a0151d3b5f8d76ef3ecc14fbf8becea20772d0f9beebf5068bfdb4a7433a91f0ba6cd56083cbb9647887439be54a65fd4bf1b",
    "quota": 1
  },
  "0x5Ac2d17495bE3d5eB58e8Cd07A0212a0abd78A17": {
    "hash": "0xe65cfeae46b4adc26c648329f2930a1730ebcc164dd470b135c5e6b962156670",
    "sig": "0x9c9b590a5249bbca45818f640b9b2c52983dc1610446978c95e1eaf6aeb5cfc4078e487590f15c77e64e3def3d755e87082dbd80bcaa6115f9183c97260303701c",
    "quota": 1
  },
  "0x8FA946f7c5D81fdE14688a7A3Eeb9b6cef7506A3": {
    "hash": "0x643fc72f6732e2de8d5ff0ba71f8761327e114d13ed269a409431337f6bfd36f",
    "sig": "0xe0d31a635d50fc7d0e542afe7d04f5ba1298045a7bc9f8ad7b8074f134e7f0dc72e5bf97fff0b1f96b5be6fb8c61f503eb10875ad066f245d46b1f4887588e0a1b",
    "quota": 1
  },
  "0xA019B9D0ACB922a7764C6937b436796c2DF1B28F": {
    "hash": "0xe98837bec0ab892be462cf845454bb98e605358d7dc9705fa2270f28efb6c908",
    "sig": "0x6589b6736dd99519779e1d7f647f7fa049d1b64a5b679c7ceef62eeb7c3224071538ed10b182cd3f4526eb38a9817ef582a5938b5d87c56b3c7ca8b557901b251c",
    "quota": 1
  },
  "0x45ddA4e5B98c6fad702B1579Cbeb42150C83ee3e": {
    "hash": "0xa424760be93ecf580324d67d99fc2944c4232f638e4ba77119bd50ecc5732144",
    "sig": "0xa91cebb299fb466ff657ea7afd1c0cd2f24977bbb45120298885e38879025f0667f003dc0af9c082d14bbac2ea5bcc47364aed47b76a2c208816425bbb6ec0cd1b",
    "quota": 1
  },
  "0x2B753a543e40A8464DA7077d40C696A692AbFAa6": {
    "hash": "0x96c0efa95132cab04d0426bde87a749bce791542e78a2b604ac7e51b7b3ac9a8",
    "sig": "0x21e81ddb8e4de825a022a7cd69193b29f4aaae7806ed886c9e398006d41315b74e43682ec9fa08ac28679073d2f6e9ed5b65c8a6a38922f04a31c7e8d40562cd1c",
    "quota": 1
  },
  "0x6Ea235dbaBEEeA4008000d2E7F432a7438B964A9": {
    "hash": "0x25663ebbb379d33dc787a6ebe73ef36d8493c2ed3e3bf1293eaf290cf2446b6b",
    "sig": "0x2a992920c6fde5bc61e6dd136903faa4886325dd4cf58fef3b5c52e37d7d0a384fae1f0a622d2f8b95a1c22214745d2b7d821a28ccd32693395654751dd9db3f1b",
    "quota": 1
  },
  "0x821bAEF1B55972B3D9840675B998bcE998DA3d33": {
    "hash": "0x46fd66eba8b686708791b00b75f3f1f134bf644bff7cb3c27c4239ced55ffa25",
    "sig": "0xaefb33f01ca93d61e3cc93dd27000f83fddbd338bc836fc82e29a2ae589fdbdb237dec0a2a5c8c9b2b4d90aedd6cd9d35dd0b4fec490dda2271656fa580186881b",
    "quota": 1
  },
  "0xD551076646DDAeCa92810c31bC42da56204310B0": {
    "hash": "0xda5bde0b0d4ea46a755d3f572c06b87103a8cf0fb58a1c2ff77876f5b06f7df7",
    "sig": "0x1ae44c79ba3d4794ac19fa51724b2aa3bbf38ddff0b06c62f502d2819a0122df06fd7cfed1e4fcb8d155b629f6ac875fa93e5c608bd0aebe66b6ec714538e4e61b",
    "quota": 1
  },
  "0xE9e79d9f6565D5Da2F4E80245A3C9aE0193a28ef": {
    "hash": "0x5bb0da40346c8f1f235e21195d55775a2d9371cfae97278125682d3789a6c5d3",
    "sig": "0xa00e49b94ad1bcfc7f4bc0639e1759f52da6113918ab177da0cdb8742c8587a85c36ae3ea576550d632000ced926665e911af1b7e0f80548d512d77ec7354c3b1b",
    "quota": 1
  },
  "0x457489A81E6B1821DAf603b5D82F8ab1b2ae0671": {
    "hash": "0x9d582174a792025ef8f69a703162442aea06340b96c5c8fbef3809d85aa4e8ef",
    "sig": "0x89c94ccf3035d778179e23d28f83999de8a14d7757b1ac8876cd1fd52616840753d58ebf7a14b60dd0c1d02170e627de6efe8074cdbab9faec727ab84f988d9e1b",
    "quota": 1
  },
  "0xB0bc504DE390D62ffC55f3D4E09D6bF25C1811A1": {
    "hash": "0x2ec0a2ecd66d25de69d29e3c1a8fd1b917596c7146bb0a7b3ce85132135f3968",
    "sig": "0xf1130513b1ca7bc672ff72e3d4bccb0097d9ce11a954dcb1b4de47f5fb81d89a630e6cb567e03e37827085198e2d7053c2edd04805cac5475e94a2b0ceb50d8f1b",
    "quota": 1
  },
  "0x8EB8b0787e019EBa0b4c516a9A0054d884Fa3A06": {
    "hash": "0x71d490aa8a5f61289fb9d0c531132f8b6111df5b781dc1c74ec0914140da7924",
    "sig": "0xf5ee7c607156e4e18b285b8a08abe7e6cc14ca1167a2ad73a1df7be94b8328400ee63d0a37f62a9b5548274cf230f93d0863de96ddbf013da58ab16acb5390c31c",
    "quota": 1
  },
  "0xe60413d7EfB619a27327542778087b14D5C56cFD": {
    "hash": "0xc3fe371665d64f8dcf0d037015e9ba441bd53ee47767edecf1ebbcae7e9c6004",
    "sig": "0x904a13f620eb8cdd80835d1ba14054ccdabd810901288f617a39cea99b0137c32165b98ddffa7acad7bb937619709d88bef797a58915db26226a6a88964e3b021b",
    "quota": 1
  },
  "0x5707b0A822A7195A425a60ebd371bb5F35cb362a": {
    "hash": "0xf4473b93fb994fc84e40ca6bb20b84fcc8a72bd8bf60bcbea3a35ee639797d8e",
    "sig": "0x596d3f0c331b5cb93dbd6892476e9144f7ec6dacbbd2fae7ae58a228c8bdf9bf1f6db0df697bd17c7273e032d7f4aefacc3fabf9d580f3abd98db0602aaf2d361b",
    "quota": 1
  },
  "0x5fD356e15e6f089333eBB02813f19E39Bf9BC3f9": {
    "hash": "0x9db4476fa74fec429ee87f6beff4af6cbb17274e9af2d7af806b8618afbd9a24",
    "sig": "0xdab27ab84eff1f64c495c2926408ec4a8e9a596f2c13e02115696585688c008a3703d12cc8aed4490e0f6b10a29b24413a77d56c30069327c667d956ff807e171c",
    "quota": 1
  },
  "0xB82B637cD46a55E82530C02f739c349545D8D1B8": {
    "hash": "0xdb5cc11278b6792003db109ec3de8c0e84640ecbf60260ac18d614a2543fbf9b",
    "sig": "0x922b8e9eed4ccb753c01e9f310d94889a2087c1da0c1410fc9bbc859252c6cc44cc676c7cfafaabb42cea20ac1412597611fca70eac0d5b95a06aeab7da1a3ce1b",
    "quota": 1
  },
  "0x51Faf05584F64F209247D3fAA74455C020a102f4": {
    "hash": "0x24c66c8f1e1fc05b62cc3eab2c74ecae59d491ad333ff1b7342003650cbf21cb",
    "sig": "0x52763747efec46ff3f9009bf69cba96b6b4d79934cfd5ea21199810a1406cb3771f431ee5a2e11094e74a10a5eda5337ef3eecd79ff30abbe70193440f6d49a51c",
    "quota": 1
  },
  "0x3442A92d3fcFb14Ce544bc04C3495946374077b1": {
    "hash": "0xa4146ac3787946474e20d373fe0388dc1b87791b5bfe095b2c6d45de1c5ccfb4",
    "sig": "0x4b976b0f209d603fbf13f1063d40e7cd736e46b238925abb3cd6683e1c72fbbc551b2308e575928bb5759502cf769c06a5166e12ce7b9f2b9423804b83854ca11c",
    "quota": 1
  },
  "0xF7c172508eBC720B74eF16214A588b58C76858D9": {
    "hash": "0xfc35e2f3e3894581b070b9556a19fdc7935553434217625043cdecbd1eb48575",
    "sig": "0x545723b9e3ceb46806562192a56480080491efd7bbe268d7abd8bc74da719412011bb356183a9ecde56c769ab5bd9242b7c1fed69f4b95872f8848eabd1740801b",
    "quota": 1
  },
  "0xf2D112ddd05a2a0Edb987191e827f925EFe1BBE1": {
    "hash": "0x8d076c4015263dda48d51212f54e22dafc1a25e661b4d1b1b448840630cd240f",
    "sig": "0xc30002a82ecf323c5a21755a7728eaa007fc0d304fba297c78af441aa7ef3cef0e32d187600fe1457acb1d9cbb26db51e04fad49cd17a6901cba0458b2e9b7741b",
    "quota": 1
  },
  "0x859B87aB782429FF66D70D8529C1C9adC845d3c2": {
    "hash": "0xb92cb0e4693bb25279863301ae8d97b61cae9e4f36908c60fd830cdd7330bdab",
    "sig": "0x4f3c37cf3f73f5d3a1fa0283cbe62ef490f5235a397c33ef7dd82cb2a5ba0359085576c89904b1578f9302953b87a7b64f2b7d397f4a00c53a38cf0389fa07ad1c",
    "quota": 1
  },
  "0x974Ab44B53a46875e4Cf0471FAEBF35b2F9d8561": {
    "hash": "0x131bc7059f5b82478a899683fbb22e96b7e8ad71d60bd93ba7d7061328feb182",
    "sig": "0x08f1d51b454823ff16a37bb91ddb6e030ed0268a0d22ec6fca56534b543bd19d370181b35214e6577123f329e94763b3a7dcbca94661c5daedaf503f99617cc01c",
    "quota": 1
  },
  "0x022d5dECf3FD1b5874f7B2eccc407ae9A9A2C190": {
    "hash": "0x1997bd2078b20de64b1d1306546a194ee8608783ae08d81317982666dffb3576",
    "sig": "0x4f4b0b3badc70000a871bb0cb147deb0aaa3536204ffc89609e7860837bdb08f1c558f42a88af30213cb9f06ca36880a5b74a29a8d80102f5e1e744971a2c71d1b",
    "quota": 1
  },
  "0x2b5F2cEaFbE19397E0a6A87EDEa49C62D9cf5dFA": {
    "hash": "0x9ab7eb75b004d97253fca4c673b11a2ad09bdd05169b6d520de2074655c31a1b",
    "sig": "0x720532a94442805a12e350741f1f5c1c4668ce37594b73b0c6a176e2e33d921f7d601125d32301e54229ea68e5fd000f518886902df4d3dd56c273f546621b781b",
    "quota": 1
  },
  "0x56f46e50FE9630C9390e49Df386D2F9EDb73D401": {
    "hash": "0x51f892850b471a0d71cd34cf796eb9a61a7e3996a456786e24ce8bd8d15ec779",
    "sig": "0x9d19ed81d1557ed38fd8b7d23d4b85f8c02b481c56d0dbc51a7801c13ecb438138ff7354ae59e09e6fbef53ad22ebb826059b3d02d349037ddce31e5337feada1c",
    "quota": 1
  },
  "0x12c54e8E08c277C7C41Ba8d8123D58c76f46c3D6": {
    "hash": "0x1d06be1f526bd208b7dcc559545e130cb64cf1eb674148beda91838daf1f367a",
    "sig": "0x874237934e02dfbbfbeb9ee0a9e1661ee904ada495b1fd627ff8b681fcdb77c45c89300dcdaf2af9ba89543f0e1808425d498ea7c0ce3ff92cb2d3b4606da20d1c",
    "quota": 1
  },
  "0xc103100D6278eA3838C9AF74ec5f90Ff43319A4E": {
    "hash": "0xa930ff522f7c6b5fef98a6476d4678c27361e0c79f0847ab04973847d390fde0",
    "sig": "0xa1bf348457b2b86c4d0c4e42d0548a5f8582a53dab889981c02c868f3ff6a4327bb38c2e99cedbffe0d5bd4a5cdf3b44d66771f9b812e3a6dc4a52ed268c25f81c",
    "quota": 1
  },
  "0xD1d56e8016ab3250241Dc42F7b89F3094B64fa6e": {
    "hash": "0x43e8a371d69062444da014edf00da38af08ab6cf0a5caa9f34005cb66d8c7c9f",
    "sig": "0x19d8296903950cedba136d888cf883d3cfd24b870e86545896cf6bf0078e51d72dbc52632cf082287dc51ec164b4b84a9ab44b7d3f37524a931908118087acaa1b",
    "quota": 1
  },
  "0x326BE4F947cEEC771f364c91d86CDeC1f84411E3": {
    "hash": "0x43507cb46485534328b543da67be3b23f13a495cd7f2f654f5a48020674f2e72",
    "sig": "0xb8aeb9c81d3c05c32369f56336c1752d445225b4e987819ce4d4d6c070c813ef06f673365bb4c927d3cc5ae7d57c1f2ba9326597e97aa3d56c8f9a783c0ed45b1c",
    "quota": 1
  },
  "0x41fc74813a3A5B6D40FC1Fc604a4F60b6dfA4B71": {
    "hash": "0x1ccb0c69d7ca5d6224373aaa1b113d7771c71c6056b5276ffb85a3b26d5907cd",
    "sig": "0xbd856de0b4e15817ca0ca892d1e189545ec1e34973b728999266ad4febe63a866db0f0d1196448eb1331b5243326fb77a9bc223004f7d67396f7508a972e08f41c",
    "quota": 1
  },
  "0xfAb9a3D37999E12252b47468D2FFD4BE15936012": {
    "hash": "0xbd01f8626c0163d50067be577594dadb805355c02d9b4f8dab691812e160bdae",
    "sig": "0xf1232e3ab870d9b6cc634aa1d4830e9ba7f6b0606e660604533e1f32d64a4fd206048af391d3085b4e68e284adb2aad72858171f3e887428e531b5b116194a491b",
    "quota": 1
  },
  "0x92a5915c0DAD03279b91d0E5b063A9B9C45C3748": {
    "hash": "0x942f2fb86b5b0042b112eb0dc1b12b43bf9ec80f5f46f608bd4a5773ee0a0bee",
    "sig": "0xab50bfb21ff3f67084f1c3a32e39e708662427b407e81270810e29350e9f47d503c615b95ad46614f4e96153088ede8888073358e4d45ceeca9baa73b9a50ab31b",
    "quota": 1
  },
  "0xE1520E903D0689db4B14dBd6e148170075230896": {
    "hash": "0x6ccc8d2fb5d17b275d253c0b6cc5e8d5d9b6e2332edd0f8e975f99f5a39bcbb1",
    "sig": "0x7f3f3d044d112e8f68d49b5951665a95aa0720632d56cfc398ebfafb13f6f8bc3a459d9194a7879a388386d126359a59991682e0a83aab0c055fd25b98b52e541b",
    "quota": 1
  },
  "0x7A3fA094a13fc9Ce247f6c4369Fca88b26954856": {
    "hash": "0x168f33f154d55abf3286f1e7dc68871b247487788a33ace86b8c961ffc81ead0",
    "sig": "0xae7b1cc55c56fae090c2726834b649c728fed56ed0f4075b03f09020111807a03e62863b3193bcb36aadc6f404ee68efb0506239ad1fea090dece8a760ea691c1c",
    "quota": 1
  },
  "0x130Ca45FDC5657B2c4B39c7dbB8fC99750bCba5c": {
    "hash": "0x8ef790b5548396d800e2170314ca91ad297e448e051cbe5eeffa5cd1328925a5",
    "sig": "0xfeb48b1db588ab9cc1d6d3bc3433e858542882c5cd7cfc107ff85d0a2f5be73d7d8eb19dc8938e05a05eb85000aa9de37a4b8ef285c3c7d7fe8d9c22e015af011b",
    "quota": 1
  },
  "0x285C1B6d09bf3F282da236a24a359b23c236eF97": {
    "hash": "0x00961a704181dfd270502dededb7676e3619e3944ac0c90a95fcc85cc5fe3772",
    "sig": "0xf5fa1bf08ff5373f6cd8733dfc7f8c802b4791ec74e810fc6045ad83dd79337c31b3a95940c8ffabe449b93cf5f31ad99734d5cb02185a9a78fed0e6ba52732e1c",
    "quota": 1
  },
  "0x268AB7458A3e39368b650c51CE904eC059D5d8F4": {
    "hash": "0xf6c380bf904bb345e0df1f1901d4bca895a2b8c378b7aaaa4edcf09acdd5ffd1",
    "sig": "0x9015002f35093c87cfbd7fbb16075fa6d803b517c07ed0f466613373d163131215276c1e4cd0eaa592d623d3df7d536770d5e2df41564a271c8a54337adee7341c",
    "quota": 1
  },
  "0x4A2088dF692B9d4d35346edF6BceD1ca25582a43": {
    "hash": "0xb98a6528186b5322ded1075b92621701c9962db372965543627444b796705cd2",
    "sig": "0x31685c8c4c501d76269118622536afba4b22fe2f981d9e4f6b0901c932f5bd1b44ad707fb15bac208efc7da91620aeb167fa34ecb895593d50d5206194fc94fc1b",
    "quota": 1
  },
  "0xDcA6Bd8C9A9746A0797243FFb53117Bc8443Ee3f": {
    "hash": "0xe3d44e82f8b08c327b3f3e7f368ce2e4b1c9c58f9f848b824b93c30a50bed1fc",
    "sig": "0xf376d03cfa885d2f8ab97f4eb0486054ffe9afb274e8ca14d3b8710ed85909c13fd14f6f7defe2b80c61ca0041f315a32b9deb597920b46a8f88345cb37f2cce1c",
    "quota": 1
  },
  "0x81E5cd19323ce7f6b36c9511fbC98d477a188b13": {
    "hash": "0x23431bf14224ce2c16ae7c620fb25c8135cfdf4ad5bb054be1b9870b7109ab69",
    "sig": "0x63ac9c8f1fd5bac78bc69dc320e4cd0ad6df6628fbc23575c2138b3190bb83cb67711bf953209433e8a7cdbacd6896157ba6ffd7dccef1ada1487efd71e9ad561b",
    "quota": 50
  },
  "0x1a9a4932F36B3641f5f63e98F77f8a1f28d85Dad": {
    "hash": "0xad80dca06b3ca485cae98929dc8f831e631e482d1338deed1a0f7add13567679",
    "sig": "0x703c6e9c94bac71c2ad51136c3c7564fe51f16285e5029d7dca0292b9028a28f682f1c9a64621858b17fb95c437fd14fcbb5169d43b74dc0dc4cca665334832b1c",
    "quota": 10
  },
  "0xD9d1C2623fBB4377d9bf29075e610A9B8b4805b4": {
    "hash": "0x38ebb7f8887bfb6d1d59039a551c0450fa5a14cf0161d2fface8833e0e80644a",
    "sig": "0x2a499f8a5eb8b354a2e54b9676c4cc4dab745bc244b51d0d49e41be9eae9c455732d039174cf510859ed655c15528bc0ce0c036b90ff89d475680b95945936e51c",
    "quota": 50
  },
  "0x8ED9D3575569C4EAC38909c4C3573CB447ab932a": {
    "hash": "0x5636f8e9db0dd7e72129e0cec8264422eab0d6389c70bb8889b0e9df5a54fea6",
    "sig": "0x39475fd5d4ae44b120834ab287b9d56f019d2287874b9730e6656b6dd26348896b711be95aaa7caba0f4a111d31d92a31c16a1c4cdd2e7edd7a779f0e5abde5f1c",
    "quota": 20
  },
  "0x63E600409F305eF517F6f341b69a24d09Fa26B97": {
    "hash": "0x6d36d9c92fa1c99205adf4d303c5bfecc40bd523ee53495e04ac5ee26318b470",
    "sig": "0x08218838eaca4548c981b492a09a984fb0bfd0b4f4d162324905d2f237842f613b7210cc454049c2747138a5dbe20c53581705f321f4b689dbd8b1f13568b9091b",
    "quota": 20
  },
  "0x28F5417f0B461cAF68259fFef48Bc098246F1E7c": {
    "hash": "0x4f61a429ecd44ed19795e55379db27f52f2f77ddd25c421000e40f0440792ea7",
    "sig": "0x8576fd61a6fff30f5515731c52518b09c88fc919cb81cc20f673a95923cf03be6e8bf7d16a5a2e31e9acb2f4f8fa57f7f0b53d80e85855e888c952bea4a118911c",
    "quota": 20
  },
  "0x554eD85f465DE3de7ca1d6583066af2a120A252a": {
    "hash": "0x54ee0203543af84019054dbd27605a8e907ec22fdf180017c84d5a258981ae6a",
    "sig": "0x36d85a41325d3ba9a3826db8ac0e6dcdea89a8c8d4dfd49ea02131113bd523da006b734da7b273ad930aa0e9055c190eeca355d22bb9d7669d2ef8690c11b61a1b",
    "quota": 20
  },
  "0xB20BCDcB3a49B36D6ae6Bf9a23d73969782388fD": {
    "hash": "0x8906f50c11295c8eb7b931d1745a10648af349a634e205a0db1eaa8890bbf4fd",
    "sig": "0xe38cd5f4dff4211d6f9542637b85936b7f4c4114f93980ed6898ce9e5756d11d262f9a8c4dbeb8de931ca98f1a9a1bc6781923ebdeeabb177cbc4c9ee934ed281c",
    "quota": 20
  },
  "0x490a4FDc7a1A35B5C2C3e9418dcE793fCe027bb2": {
    "hash": "0x821ccbbcab3a5342b60d1e3d33600e80c4721096437dc18d27081cf78d479c24",
    "sig": "0x33cfeb76958179a364437aa629b36116c2f2cba94e8e3305564bed812a35dd4c399ca7af2876f328ae32b44bd9dfb73e0833dc0e7c8a4358554d597975fc57c71c",
    "quota": 5
  },
  "0x131c504D48D95B7FA06131746355dD368f0a3bfB": {
    "hash": "0x86b0d0be863ac52e775ac887dc1152e1b27e4f25a18311d0aaeeb1d4dacd60fa",
    "sig": "0x404f891b27356b102ce0a7f7ba054977965d835fce9914ec02951dbd46e1889e51a918936ce38966d9cfb725b0917c9221f0c21b3d2caa6839f01c7a58d96d5e1b",
    "quota": 1
  },
  "0x9de52D49bD9a278b80e91EC5fda95398b592ee9c": {
    "hash": "0xf57ba2cf53da863210830f4dc29c7bf21f8ac7f89cb0ab797eafd163cd80b444",
    "sig": "0xc6117d545b97bd6283bdac140aa096b7131d439d0175b09b6c1eadbd734c9e210b882ef8c24eb6faa1a41c14f9279b12fa499692698ea0d35455d19e64a34b701c",
    "quota": 1
  },
  "0x25De6ee9BB67DcA9654259843682bE875ab85b5f": {
    "hash": "0xfa83047fb7c9e89a7b8f1f44fd62a20c833d23da942e2041bd467193703d4253",
    "sig": "0xd39ad7a5a9e39d57ad2ce0a060d4bd3d3a4a1584db84906b84db03cec618525a69c40ce1437f072ee697f3f2178911ad9ecbb231398544ce99dfc3d5979723641c",
    "quota": 5
  },
  "0x0e443C3Ae40eE48dDFd7BfD3Fe27822B393A29EB": {
    "hash": "0x0040afadbd0c50d41573cee5df1c5563cc92bb7b428a7296e023e3d115d91ee5",
    "sig": "0xcb5855834bcaff626ccd2185270ca2c733335d47448073e66a187f775f9f15f25f7460ea0d0e016eb63d618b9fc3c9340080923ac516927b217e9fbf494e7c881c",
    "quota": 5
  },
  "0x39e2A1128A2A6a756c7c8ed501ff586A883C875D": {
    "hash": "0x9c1fe7e255bb753d32f3d48dfb38e919ad747aacbfb0e21162456e4460691928",
    "sig": "0x0d122c5c890b5902df259e495313b975eeac890f7d795b833247951e8df715b87db885a54532ef372d6c18ee7965daed2f5a889afcaa267a6a501277b2fbafec1c",
    "quota": 1
  }
}