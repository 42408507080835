export const Config = {
	hhDiscordUrl: "http://discord.gg/3Z6M3JFUBV",
	hhTwitterUrl: "http://twitter.com/HunkyHeroes",
	hhOpenseaUrl: "http://opensea.io/collection/hunkyheroes",
	chiefTwitterUrl: "http://twitter.com/HunkyHeroes",
	mayorTwitterUrl: "http://twitter.com/HunkyHeroes",
	coinFuPandaTwitterUrl: "http://twitter.com/CoinFuPanda",

	openseaLink: "https://opensea.io/collection/hunkyheroes",
	openseaAssetRoot: "https://opensea.io/assets/"
}