const addresses = {
  heroesLocal: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  heroesRopsten: "",
  //heroesRinkeby: "0xA96711fDe1973C1318579639aF2223F3337f6A42", //this uses legitHash() and legitSigner()
  //heroesRinkeby: "0x0428DD26f29B114E54c9022b3363489dA2891687", //used totalSupply() which bugged ids when burning
  //heroesRinkeby: "0xCC70110318b0d5F2d69021fdF2E0410178bDaA63",
  //heroesRinkeby: "0xde4cc9B3e25a28D3Fb700B20f3A3F392fff094E0",
  //heroesRinkeby: "0xcEd06f5AdF23aEd223E43A6B25B6fDF573afe9F5",
  heroesRinkeby: "0x10D6AeD0074689CAeF8CC33253402fDD0785C9C9",
  
  heroesMainnet: "0xD31Bd18ec25AbE415a4b238DC196A92c3201d13D",
};

export default addresses;
