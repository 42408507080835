//import connectors from web3React and set their supported chains
//export each to be used in the App
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const POLLING_INTERVAL = 12000;
//get eth api endpoints
const RPC_URLS = {
	1: process.env.REACT_APP_RPC_URL_1, //mainnet
	3: process.env.REACT_APP_RPC_URL_3, //ropsten
	4: process.env.REACT_APP_RPC_URL_4 //rinkeby
}

export const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 31337]});
export const walletConnect = new WalletConnectConnector({ 
	rpc: { 1: RPC_URLS[1], 3: RPC_URLS[3], 4: RPC_URLS[4]},
	qrcode: true,
	pollingInterval: POLLING_INTERVAL
});