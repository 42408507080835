import React from "react";

export function Section(props) {
	//useful section block for page
	return(
		<div 
			id={props.id}
			className={
			props.className
				? "section-wrap " + props.className
				: "section-wrap"
			}
		>
			{props.children}
		</div>
	);
}

export function SectionContentFlex(props) {
	//flex style simple content component
	return(
		<div className={
			props.className 
				? "section-content " + props.className
				: "section-content"
			}
		>
			{props.children}
		</div>
	);
}

export function MessagePost(props) {
	//simple standard message
	return(
		<div className="message-post">
			<h2>{props.title}</h2>
			<h3>{props.subtitle}</h3>
			<p>{props.message}</p>
		</div>
	);
}

export function Panels(props) {
	//layout N panels next to each other
	let panels = [];
	for (let i = 0; i < props.items.length; i++) {
		panels.push(
			<div className="panel-item-wrap" key={i}>
				<img 
					className="panel-item-img"
					src={props.items[i]}
					alt={`panel ${i}`}
				/>
			</div>
		);
	}

	return (
		<div className={
			props.className 
			? "panels-wrap " + props.className
			: "panels-wrap"
		}>
			{panels}
		</div>
	);
}

export function BannerTitles(props) {
	return(
		<div className="banner-titles">
			<div className="left-banner-wrap">
				<img className="left-banner-img"
					src={props.leftBanner}
					alt="hunky"
				/>
			</div>
			<div className="right-banner-wrap">
				<img 
					className="right-banner-img"
					src={props.rightBanner}
					alt="heroes"
				/>
			</div>
		</div>
	);
}

export function SideBySide(props) {
	return(
		<div className={
			props.rowOrCol === "col"
				? "side-by-side-wrap flex-col flex-nowrap"
				: "side-by-side-wrap flex-row flex-nowrap"
			}
		>
			<div className="side-by-side-item">
				<img 
					className="side-by-side-img"
					src={props.imgSrc1}
					alt={props.imgAlt1}
				/>
			</div>
			<div className="side-by-side-item">
				<img 
					className="side-by-side-img"
					src={props.imgSrc2}
					alt={props.imgAlt2}
				/>
			</div>
		</div>
	);
}

export function FourImageGrid(props) {
	return(
		<div className="four-image-grid-wrap flex-col flex-nowrap">
			<SideBySide 
				rowOrCol="row"
				imgSrc1={props.imgSrc_1}
				imgSrc2={props.imgSrc_2}
				imgAlt1={props.imgAlt_1}
				imgAlt2={props.imgAlt_2}
			/>
			<SideBySide 
				rowOrCol="row"
				imgSrc1={props.imgSrc_3}
				imgSrc2={props.imgSrc_4}
				imgAlt1={props.imgAlt_3}
				imgAlt2={props.imgAlt_4}
			/>
		</div>
	);
}