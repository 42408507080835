import React from "react";
import { Config } from "./config/default";
import { ethers } from "ethers";

//web3 stuff
//import { Web3ReactProvider, UnsupportedChainIdError } from "@web3-react/core";
import { useWeb3React } from "@web3-react/core";
import { useAutoConnectInjected } from "./hooks/useWeb3React";
//import { useInjectedMonitor } from "./hooks/useWeb3React";

import './App.css';
// import { ComingSoon } from "./components/ComingSoon.js";
import { 
  //NavBar,
  //LinkButton,
  LinkCard,
  SocialButtonGroup,
  SocialLogoButton
} from "./components/Nav.js";
import { 
  Section, 
  SectionContentFlex, 
  MessagePost, 
  Panels,
  BannerTitles,
  FourImageGrid
} from "./components/Layout.js";
import { RoadmapContent } from "./components/Roadmap.js";
//minting
import { MintBar } from "./components/MintingComponents.js";
//connections to web3
import { OpenConnectModal } from "./components/ConnectingComponents.js";

//contract interaction stuff 
import addresses from "./contracts/src/addresses.js";
import abis from "./contracts/src/abis.js";

//whitelisting
import { whitelist } from "./data/whitelistJSON.js";

//media
//import holdingImg from "./media/holdingLogoSquare.png";
import theChief_Logo from "./media/theChief.png";
import theMayor_Logo from "./media/theMayor.png";
import coinFuPanda_Logo from "./media/coinFuPanda.png";
import hh0_600 from "./media/600/hh0.png";
import hh1_600 from "./media/600/hh1.png";
import hh2_600 from "./media/600/hh2.png";
import hh3_600 from "./media/600/hh3.png";
import hh4_600 from "./media/600/hh4.png";
//import hh8_600 from "./media/600/hh8.png";
import hh14_600 from "./media/600/hh14.png";
import hh17_600 from "./media/600/hh17.png";
//import hh19_600 from "./media/600/hh19.png";
import heroGif_600 from "./media/heroGif_600.gif";
// import hero1 from "./media/hero1.png";
// import hero2 from "./media/hero2.jpeg";
// import hero3 from "./media/hero3.jpeg";
// import hero4 from "./media/hero4.png";
// import hero5 from "./media/hero5.jpeg";
// import hero6 from "./media/hero6.png";
// import heroGif1a from "./media/gifs/heroGif600_1a.gif";
// import heroGif1b from "./media/gifs/heroGif600_1b.gif";
// import heroGif1c from "./media/gifs/heroGif600_1c.gif";
// import heroGif1d from "./media/gifs/heroGif600_1d.gif";
// import heroGif2 from "./media/gifs/heroGif600_2.gif";
// import heroGif3 from "./media/gifs/heroGif600_3.gif";
// import heroGif4 from "./media/gifs/heroGif600_4.gif";
import heroGif5 from "./media/gifs/heroGif600_5.gif";
import comic1_Panel1 from "./media/comic1_Panel1.png";
import comic1_Panel2 from "./media/comic1_Panel2.png";
import comic2_Panel1 from "./media/comic2_Panel1.png";
import comic2_Panel2 from "./media/comic2_Panel2.png";
import hunkyBannerText from "./media/banner/HUNKY_TEXT.png";
import heroesBannerText from "./media/banner/HEROES_TEXT.png";
import openseaLogoWhite from "./media/Opensea-Logomark-White.svg";
import twitterLogoWhite from "./media/Twitter-Logo-White.svg";
import discordLogoWhite from "./media/Discord-Logo-White.svg";

// import { SiDiscord, SiTwitter } from "react-icons/si";

//const OPENSEAURL = Config.openseaLink;
//const OPENSEA_ASSET_ROOT = Config.openseaAssetRoot;

function getContractByNetwork(chainId_) {
  let returnAddress;
  switch( String(chainId_) ) {
    case "1":
      //console.log("HEROES CONTRACT ADDRESS: ", addresses.heroesMainnet);
      returnAddress = addresses.heroesMainnet;
      break;
    case "3": 
      //console.log("HEROES CONTRACT ADDRESS: ", addresses.heroesRopsten);
      returnAddress = addresses.heroesRopsten;
      break;
    case "4":
      //console.log("HEROES CONTRACT ADDRESS: ", addresses.heroesRinkeby);
      returnAddress = addresses.heroesRinkeby;
      break;
    case "31337":
      returnAddress = addresses.heroesLocal;
      break;
    default:
      returnAddress = addresses.heroesMainnet;
  };
  if (returnAddress.startsWith("0x")) {
    //console.log("Heroes contract address: ", returnAddress);
    return returnAddress;
  } else {
    return null;
  }
}

function App() {
//use useWeb3React() to access web3
  //'Connectors' connect to web3, i.e. metamask, ledger, walletconnect...
  //'library' is an ethers.js provider (set in getLibrary())
  //the useful state is stored as context (see react docs) and accessible in 
  //any child of the <Web3ReactProvider> component
  const Web3ReactContext = useWeb3React();
  //console.log("DEBUG: Web3ReactContext: ", Web3ReactContext);
  const { 
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error 
  } = Web3ReactContext;
  const [ numTokensMinted, setNumTokensMinted ] = React.useState();
  //*debug*console.log("numTokensMinted: ", numTokensMinted);
  //const ipfsTokenCid = Config.ipfsTokenCid;

  const heroesAddress = getContractByNetwork(chainId);
  //console.log("DEBUG: heroesAddress: ", heroesAddress);
  
  //const openseaImageBaseUrl = OPENSEA_ASSET_ROOT + heroesAddress + '/';
  //console.log("openseaImageBaseUrl: ", openseaImageBaseUrl);
  //*debug*console.log("derpyAddress: ", derpyAddress);

  //*debug*console.log("connector: ", connector);
  //*debug*console.log("library: ", library);
  //*debug*console.log("account: ", account);
  //*debug*console.log("chainId: ", chainId);
  //*debug*console.log("active: ", active);
  //*debug*console.log("error: ", error);
  //*debug*console.log("Web3ReactContext: ", Web3ReactContext);


  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedAutoConnect = useAutoConnectInjected();
  //*debug*console.log("triedAutoConnect = " + triedAutoConnect);
  
  //REMOVE THE AUTO CONNECT ATTEMPT BECAUSE IT FREEZES THE PAGE 
  //IF METAMASK IS NOT CONNECTED TO IMMEDIATELY
  //useInjectedMonitor(!triedAutoConnect || !!activatingConnector);
  
  //get the contract's current token supply
  const getNumTokensMinted = React.useCallback(() => {
    //*debug*console.log("firing getNumTokensMinted");
    async function getTokenSupply (provider_) {
      
      let prov;
      if (provider_) {
        prov = provider_;
      } else {
        prov = ethers.getDefaultProvider(chainId, {infura: process.env.REACT_APP_INFURA_DEFPROV_KEY});
      };

      const heroesContract = new ethers.Contract(heroesAddress, abis.heroes, prov);
      const tokenSupply = (await heroesContract.currentSupply()).toString();
      //*debug*console.log("tokenSupply: ", tokenSupply);
      //*debug*console.log("typeof tokenSupply: ", typeof tokenSupply);
      if (!isNaN(parseInt(tokenSupply, 10))) {
        //*debug*console.log("setNumTokensMinted to :", tokenSupply)
        setNumTokensMinted(tokenSupply);
      } else {
        console.log("could not interpret the returned currentSupply(): ", tokenSupply);
      };
    };

    library && getTokenSupply(library);

  }, [library, setNumTokensMinted, heroesAddress, chainId]);

  React.useEffect(() => {
    getNumTokensMinted();
  }, [connector, getNumTokensMinted, chainId]);



  return (
    <div className="App">
      {/*<Web3ReactProvider getLibrary={getLibrary}>*/}
          
        {/*<NavBar>
          <LinkButton
            buttonClass={"btn-type-nav"}
            buttonTitle={"Welcome"}
            linkToId={"id-welcome"}
          />
          <LinkButton
            buttonClass={"btn-type-nav"}
            buttonTitle={"Rarity"}
            linkToId={"id-rarity"}
          />
          <LinkButton
            buttonClass={"btn-type-nav"}
            buttonTitle={"Roadmap"}
            linkToId={"id-roadmap"}
          />
          <LinkButton
            buttonClass={"btn-type-nav"}
            buttonTitle={"Story"}
            linkToId={"id-story-0"}
          />
          <LinkButton
            buttonClass={"btn-type-nav"}
            buttonTitle={"Team"}
            linkToId={"id-team"}
          />
        </NavBar>*/}
        <header className="App-header" role="img">
          <BannerTitles
            leftBanner={hunkyBannerText}
            rightBanner={heroesBannerText}
          />
          <SocialButtonGroup>
            <SocialLogoButton
              href_={Config.hhTwitterUrl}
              buttonLogo={
                <img 
                  src={twitterLogoWhite}
                  alt="twitter"
                  className="social-logo-img"
                />
              }
            />
            <SocialLogoButton
              href_={Config.hhDiscordUrl}
              buttonLogo={
                <img 
                  src={discordLogoWhite}
                  alt="discord"
                  className="social-logo-img"
                />
              }
            />
            <SocialLogoButton
              href_={Config.hhOpenseaUrl}
              buttonLogo={
                <img 
                  src={openseaLogoWhite}
                  alt="opensea"
                  className="social-logo-img"
                />
              }
            />
          </SocialButtonGroup>
   {/*       <ComingSoon/>*/}

          {/*<h1 className="App-header-title">
            Hunky Heroes
          </h1>*/}
  {/*        <button>
            Mint a Hero
          </button>*/}
        </header>
        

        <h2 
          className="text-center" 
          style={{
            color: "black", 
            margin: "auto"
          }}>
          Minting LIVE!
        </h2>
        <h4 
          className="text-center"
          style={{
            margin: "0.25em auto"
          }}
        >
          at a Heroic 0.02 ETH!
        </h4>

        <body className="App-body" id="id-header">

        {/*MINTING SECTION*/}
{/*
          <Section id="id-minting" className="mint-section">
            {
              account &&
                <div>
                  {
                    whitelist.hasOwnProperty(account) ?
                    (
                      
                      <p>
                        {account}<br/>
                        is on the hunky whitelist!
                      </p>
                    ) : (
                      <p>
                        Great to have you on board Hero! 
                      </p>
                    )
                  }
                  <p>
                    Minting starts on the 21st December!<br/>
                    get Hyping &nbsp;
                    <a 
                      href={Config.chiefTwitterUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-pink link-hover-white"
                    >
                      #HunkItUp @HunkyHeroes
                    </a>!
                  </p>
                </div>
            }
            <OpenConnectModal
              connector={connector} 
              triedAutoConnect={triedAutoConnect}
              activatingConnector={activatingConnector}
              setActivatingConnector={setActivatingConnector}
              activate={activate}
              deactivate={deactivate}
              error={error}
              active={active}
              account={account}
              connectMsg={<h3>Connect Wallet</h3>}
              buttonClassName={"btn-type-b btn-wide"}
            />
          </Section>
*/}

{/*START PRESALE COMMENT*/}
          <Section id="id-minting" className="mint-section">
            <div className="mint-section-wrap flex-col">
              { numTokensMinted &&
                <h4 className="mint-status">
                  {numTokensMinted}/6500 Heroes Minted!
                </h4>
              }
              {
                account ? (
                  <>
                  <MintBar 
                    mintFormId="mintFormId_0"
                    mintFormIdWl="mintFormId_99"
                    title={"MINT HEROES!"}
                    //subtitle={`Catch up to 50 in one go!`}
                    message={{
                      firstline: "Welcome Hunkster!",
                      nextline: "Mint up to 100 in one go!"
                    }}
                    mintButtonMessage={"MINT HEROES!"}
                    mintButtonClassName={"btn-type-b btn-wide"}
                    provider={library} 
                    updateTokenSupply={getNumTokensMinted}
                    abis={abis}
                    heroesAddress={heroesAddress}
                    maxNumber={100}
                    account={account}
                  />
                  <OpenConnectModal
                      connector={connector} 
                      triedAutoConnect={triedAutoConnect}
                      activatingConnector={activatingConnector}
                      setActivatingConnector={setActivatingConnector}
                      activate={activate}
                      deactivate={deactivate}
                      error={error}
                      active={active}
                      account={account}
                      connectMsg={<h3>Connect Wallet</h3>}
                      buttonClassName={"btn-type-c btn-wide btn-short"}
                    />
                    </>
                ) : (
                  <div>
                    <h2>
                      HUNKY HERO MINTING IS LIVE!
                    </h2>
                    
                    <OpenConnectModal
                      connector={connector} 
                      triedAutoConnect={triedAutoConnect}
                      activatingConnector={activatingConnector}
                      setActivatingConnector={setActivatingConnector}
                      activate={activate}
                      deactivate={deactivate}
                      error={error}
                      active={active}
                      account={account}
                      connectMsg={<h3>Connect Wallet</h3>}
                      buttonClassName={"btn-type-b btn-wide"}
                    />
                  </div>
                )
              }
              
              {
                heroesAddress &&
                  <p className="word-break-all">
                    <strong>Check you are interacting with the Hunky Heroes contract</strong><br/>
                    {heroesAddress}
                  </p>
              }
            </div>
          </Section>
{/*END PRESALE COMMENT*/}

{/*          <OpenConnectModal 
            connector={connector} 
            triedAutoConnect={triedAutoConnect}
            activatingConnector={activatingConnector}
            setActivatingConnector={setActivatingConnector}
            activate={activate}
            deactivate={deactivate}
            error={error}
            active={active}
            account={account}
            connectMsg={"Connect Wallet"}
            buttonClassName={"btn-type-a"}
          />*/}

          <Section id="id-welcome" className="welcome-wrap">
            <SectionContentFlex className="flex-c-row-md-col">
              <MessagePost
                title="Welcome, Heroes"
                subtitle="Welcome to the Hunkyverse"
                message="Hunky heroes join forces! Welcome to the hunkiest club on Ethereum."
              />
              <img 
                className="section-content-img" 
                src={hh0_600} 
                alt="welcome"
              />
            </SectionContentFlex>
          </Section>

          <Section id="id-rarity" className="rarity-wrap">
            <SectionContentFlex className="flex-c-row-md-col-rev">
              <MessagePost
                title="Hunky Rarity"
                subtitle="Every Hero is a Hunk"
                message="Hand drawn and programmatically generated, each Hero is unique! Which traits do yours have?"
              />
              <img 
                className="section-content-img" 
                src={heroGif_600} 
                alt="rarity"
              />
            </SectionContentFlex>
          </Section>

          <Section id="id-roadmap" className="roadmap-wrap posn-relative">
            <h2 className="text-center">
              Roadmap
            </h2>
            <h4 className="text-center">
              From zero to Hunky Hero - here's the hunky plan
            </h4>
            <RoadmapContent/>
          </Section>

          <Section id="id-story-0" className="flex-col">
            <MessagePost
              title="The Story So Far..."
            />
            <Panels 
              className="flex-c-row-md-col"
              items={[comic1_Panel1, comic1_Panel2]}
            />
            
            <SectionContentFlex className="flex-c-row-md-col-rev">
              <MessagePost
                subtitle="Justice, Truth, Hunkyness."
                message="These are 3 traits that our Hunky Hero abides by at all costs.  After being left in Citysville with no memories Hunky Hero was in disarray.  Stranded with no family or friends in sight, our hallowed hunkster had no clue how or why he was on this planet.  One thing he did know as a matter of absolute fact though, is that..."
              />
              <img 
                className="section-content-img" 
                src={hh2_600} 
                alt="hunk2"
              />
            </SectionContentFlex>

            <SectionContentFlex className="flex-c-row-md-col">
              <MessagePost
                subtitle="he must take down evil-doers and look Hunky while doing it."
                message="With this one immutable truth in his head at all times he began to form a squadron of likeminded Heroes who wanted to protect the Universe. They then formed the group known as the Hunky Heroes Genesis team."
              />
              <FourImageGrid 
                imgSrc_1={hh14_600}
                imgSrc_2={hh3_600}
                imgSrc_3={hh1_600}
                imgSrc_4={hh4_600}
                imgAlt_1={"hunk1"}
                imgAlt_2={"hunk2"}
                imgAlt_3={"hunk3"}
                imgAlt_4={"hunk4"}
              />
              {/*<img 
                className="section-content-img" 
                src={hh1_600} 
                alt="welcome"
              />*/}
            </SectionContentFlex>

            <SectionContentFlex className="flex-c-row-md-col-rev">
              <MessagePost
                subtitle="Villains trembled at the sight of them"
                message="and for the most part the planet was in a time of peace.  That is until the Android, his arch nemesis, and the evil doctor who created him began their master plan of world domination.  After a fight between Hunky Hero and Android, the evil doers were able to obtain some of Hunky Hero's blood and escape before their demise."
              />
              <img 
                className="section-content-img" 
                src={heroGif5} 
                alt="hunk3"
              />
            </SectionContentFlex>

            <SectionContentFlex className="flex-c-row-md-col">
              <MessagePost
                subtitle="They used Hunky Hero's DNA"
                message="to clone him exponentially using different traits and crossing genes with many types of aliens and substances that the doctor had procured throughout his time on this planet.  Soon there after these evil doers created 6500 Hunksters that they then called the Degenerate Gang of Hunky Heroes.  Some were evil, some were good, but all were hunky.  Will this dastardly plan leave the planet in disarray? Stick around and find out..."
              />
              <img 
                className="section-content-img" 
                src={hh17_600} 
                alt="hunk4"
              />
            </SectionContentFlex>
          </Section>

          <Section id="id-team" className="team-wrap">
            <SectionContentFlex className="flex-col">
              <h2>
                Team
              </h2>
              <div className="team-gallery flex-c-row-md-col">
                <LinkCard
                  _href={Config.chiefTwitterUrl}
                  imgSrc={theChief_Logo}
                  imgAlt={"The Chief"}
                  cardTitle={"The Chief"}
                  cardText={"Music producer turned NFT Maximalist. Let’s get our hero on."}
                />
                <LinkCard
                  _href={Config.mayorTwitterUrl}
                  imgSrc={theMayor_Logo}
                  imgAlt={"The Mayor"}
                  cardTitle={"The Mayor"}
                  cardText={"Artist with no formal training. Just tryna keep it Hunky for ya."}
                />
                <LinkCard
                  _href={Config.coinFuPandaTwitterUrl}
                  imgSrc={coinFuPanda_Logo}
                  imgAlt={"CoinFuPanda"}
                  cardTitle={"Coin Fu Panda"}
                  cardText={"Web3.0 & contract dev. Has also been known to draw pixels."}
                />
              </div>
            </SectionContentFlex>
          </Section>
          <Section id="id-story-1" className="flex-col">
            <Panels 
              className="flex-c-row-md-col"
              items={[comic2_Panel1, comic2_Panel2]}
            />
          </Section>
        </body>
        <footer className="App-footer text-center" id="id-footer">
          <h4>Keepin it Hunky since 2021</h4>
        </footer>
        
      {/*</Web3ReactProvider>*/}
    </div>
  );
}

export default App;
