import React from "react";
import { ethers, BigNumber, utils } from "ethers";
import { whitelist } from "../data/whitelistJSON.js";
//import { Button } from "../components"
//import NumericInput from "react-numeric-input";


class MintButton extends React.Component {
  constructor (props) {
    super(props);
    this.title = "MINT";
    this.type = "submit";
    this.formId_ = "default-form-id";
    this.className_ = "default-mint-button";

    if (!this.props.title || (typeof(this.props.title) !== "string")) {
      this.title = "MINT";
    } else {
      this.title = this.props.title;
    };

    if (!this.props.type || !(["submit", "button", "reset"].includes(this.props.type))) {
      this.type = "submit";
    } else {
      this.type = this.props.type;
    };

    if (!this.props.formId || (typeof(this.props.formId) !== "string")) {
      this.formId_ = "";
    } else {
      this.formId_ = this.props.formId;
    };

    if (!this.props.buttonClassName || (typeof(this.props.buttonClassName) !== "string")) {
      this.className_ = "";
    } else {
      this.className_ = this.props.buttonClassName;
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  };

  handleSubmit(event) {
    event.preventDefault();
    // console.log("firing handleSubmit in MintButton");
    this.props.onMintSubmit(event.target.value);
  };

  render () {
    return (
      <button 
        type={this.type}
        onClick={this.handleSubmit}
        className={this.className_}
        form={this.formId_}
      >
      {this.title}
      </button>
      );
  }
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.formId_ = "default-form-id";
    this.className_ = "default-input-number";
    this.formClassName_ = "default-form-number";
  
    if (!this.props.formId || (typeof(this.props.formId) !== "string")) {
      this.formId_ = "";
    } else {
      this.formId_ = this.props.formId;
    };
  
    if (!this.props.inputClassName || (typeof(this.props.inputClassName) !== "string")) {
      this.className_ = "";
    } else {
      this.className_ = this.props.inputClassName;
    };
  
    if (!this.props.formClassName || (typeof(this.props.formClassName) !== "string")) {
      this.formClassName_ = "";
    } else {
      this.formClassName_ = this.props.formClassName;
    };

    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleNumSubmit = this.handleNumSubmit.bind(this);
    // this._handleNumChange = this._handleNumChange.bind(this);
  };

  handleNumChange(event) {
    // console.log("firing handleNumChange in NumberInput");
    this.props.onNumChange(event.target.value); 
  };

  // _handleNumChange(event) {
  //   //*debug*console.log("firing _handleNumChange(event) with event=", event);
  //   //*debug*console.log("event.valueAsString: ", event.valueAsString);
  //   //*debug*console.log("event.valueAsNumber: ", event.valueAsNumber);
  //   //*debug*console.log("isNan(event.valueAsNumber): ", isNaN(event.value));
  //   //*debug*console.log("isNan(event.valueAsString): ", isNaN(event.value));
  //   //*debug*console.log("parseInt(event, 10): ", parseInt(event, 10));
  //   this.props.onNumChange(parseInt(event, 10));
  // };

  // _handleNumSumbit(event) {
  //   event.preventDefault();
  //   this.props.onNumSubmit(parseInt(event, 10));
  // };

  handleNumSubmit(event) {
    event.preventDefault();
    // console.log("firing handleNumSubmit in NumberInput");
    // console.log("event.target.value: ", event.target.value);
    this.props.onNumSubmit(event.target.value); //.valueAsNumber); //
  };

  render() {
    // console.log("render NumberInput");
    return (
      <form 
        className={this.formClassName_}
        id={this.formId_}
        onSubmit={this.handleNumSubmit}
        > 
        <div className="mint-bar-input-wrapper">
          <input 
            className={this.className_}
            inputMode="numeric"
            type="number"
            max={this.props.maxNumber}
            min={1}
            step={1}
            value={this.props.storedValue}
            onChange={this.handleNumChange} 
          />
{/*          <NumericInput 
            className={this.className_}
            inputMode="numeric"
            type="text"
            max={50}
            min={1}
            step={1}
            value={this.props.storedValue}
            onChange={this._handleNumChange}
            strict />*/}
        </div>
      </form>
      );
    }
}

export class MintingContent extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId_ = this.props.mintFormId;
    this.state = {
      numToMint: 5,
      message: ''
    };
    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleMint = this.handleMint.bind(this);
    this.getTokenSupply = this.getTokenSupply.bind(this);
  };

  //EVERY TIME THE NUMBER INPUT BOX CHANGES
  handleNumChange(e) {
    //*debug*console.log("firing handleNumChange in MintBar with payload: ", e);
    try {
      if (isNaN(parseInt(e, 10))) {
        this.setState({ numToMint: 0});
        //throw TypeError;
      } else {
        this.setState({ numToMint: e });
        //*debug*console.log("input num changed: ", this.numToMint); 
      };
    } catch (error) {
      console.error(`Error in MintBar id ${this.mintFormId_}.\nMake sure you're inputting an integer!\n${error}`);
    };
  };

  //MINT DIRECTLY TO THE HEROES CONTRACT
  async handleMint() {
    //*debug*console.log("firing handleSubmit in MintBar");
    //*debug*console.log("state in MintBar at submit time: ", this.state.numToMint);
    //get a signer
    try {
      const signer = await this.props.provider.getSigner()
      const sigAddress = await signer.getAddress();
      console.log("MintingContent: in handleMint(): sigAddress: ", sigAddress);
      console.log("MintingContent: in handleMint(): heroesAddress: ", this.props.heroesAddress);

      //connect to the contract
      const heroesContract = new ethers.Contract(this.props.heroesAddress, this.props.abis.heroes, signer);
      //get the price
      const mintPrice = await heroesContract.mintPrice();
      //*debug*console.log("mintPrice.toString() from contract: ", mintPrice.toString());
      const totalCost = mintPrice.mul(BigNumber.from(this.state.numToMint));
      const formattedTotalCost = utils.formatUnits(totalCost, "ether");
      //*debug*console.log("formattedTotalCost: ", formattedTotalCost);
      console.log(
        `Minting ${this.state.numToMint} tokens at ${utils.formatEther(mintPrice)} ETH each.\n` +
        `Total = ${utils.formatEther(totalCost)} ETH`
      );
      //mint the tokens
      const tx = await heroesContract.mintHero( BigNumber.from(this.state.numToMint),
        {
          value: utils.parseEther(formattedTotalCost)
        }
      );
      //const txReceipt = await tx.wait();
      console.log(
        `Minted ${this.state.numToMint} Hunky Heroes, at a total cost of ${formattedTotalCost} ETH.\n` +
        `Value returned in receipt: ${utils.formatEther(tx.value)} ETH.`
      );

      } catch (error) {
        if (error.message.includes("Sale is not live")) {
          this.setState({ errMsg: {code: "Sale is not live"} });
          console.error("Sale is not live");
        } else {
          console.log("error during wl mint: ", error);
          this.setState({ errMsg: error });
      };
    };
    try {
      //update the token supply
      this.getTokenSupply();
    } catch (err) {
      console.log("error updating the contract's token supply: ", err);
    };
  };

  
  getTokenSupply() {
    // console.log("firing getTokenSupply() in MintBar");
    this.props.updateTokenSupply();
  };

  render() {
    //*debug*console.log("render MintBar constructor");
    return (
      <div className="mint-bar-content-wrap">
        <div className="mint-bar-number-input-wrap">
          <NumberInput 
            formClassName="mint-bar-form" 
            inputClassName="input-num mint-bar-number" 
            //provider={this.props.provider} 
            formId={this.mintFormId_} 
            onNumChange={this.handleNumChange}
            onNumSubmit={this.handleMint} 
            storedValue={this.state.numToMint}
            maxNumber={this.props.maxNumber}
          />
        </div>
        <div className="mint-bar-button-wrap">
          {
            !!this.state.errMsg && 
              <p>Error during mint!<br/>{this.state.errMsg.code}<br/>Press F12 for details</p>
          } 
          <MintButton
            title={this.props.mintButtonMessage}
            buttonClassName={
              this.props.mintButtonClassName ?
              this.props.mintButtonClassName :
             "btn-type-a"
            }
            type="submit"
            formId={this.mintFormId_}
            //provider={this.props.provider}
            onMintSubmit={this.handleMint} 
          />
        </div>
      </div>
    );
  };
}


export class WhitelistMintingContent extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId_ = this.props.mintFormId;
    this.state = {
      numToMint: 1,
      message: '',
      errMsg: ''
    };
    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleMint = this.handleMint.bind(this);
    this.getTokenSupply = this.getTokenSupply.bind(this);
  };

  //EVERY TIME THE NUMBER INPUT BOX CHANGES
  handleNumChange(e) {
    //*debug*console.log("firing handleNumChange in MintBar with payload: ", e);
    try {
      if (isNaN(parseInt(e, 10))) {
        this.setState({ numToMint: 0 })
        //throw TypeError;
      } else {
        this.setState({ numToMint: e });
        //*debug*console.log("input num changed: ", this.numToMint); 
      };
    } catch (error) {
      console.error(`Error in MintBar id ${this.mintFormId_}.\nMake sure you're inputting an integer!\n${error}`);
    };
  };

  //MINT DIRECTLY TO THE HEROES CONTRACT
  async handleMint() {
    //*debug*console.log("firing handleSubmit in MintBar");
    //*debug*console.log("state in MintBar at submit time: ", this.state.numToMint);
    //get a signer
    try {
      const signer = await this.props.provider.getSigner()
      //const sigAddress = await signer.getAddress();
      // DEBUG: console.log("MintingContent: in handleMint(): sigAddress: ", sigAddress);
      // DEBUG: console.log("MintingContent: in handleMint(): heroesAddress: ", this.props.heroesAddress);

      //connect to the contract
      const heroesContract = new ethers.Contract(
        this.props.heroesAddress, 
        this.props.abis.heroes, 
        signer
      );

      // DEBUG: console.log("WLMC: handleMint(): Minting token on the whitelist");
      console.log("Minting token on the whitelist...");
      // DEBUG: console.log("WLMC: handleMint(): account: ", this.props.account);
      // DEBUG: console.log("WLMC: handleMint(): wlHash: ", this.props.wlHash);
      // DEBUG: console.log("WLMC: handleMint(): wlSig: ", this.props.wlSig);
      
      //pass the whitelist validation hash and sig to mint fcn
      //const tx =
      await heroesContract.herolistMint(
        this.props.wlSig,
        BigNumber.from(this.props.wlQuota),
        BigNumber.from(this.state.numToMint)
        );
        //no longer passing the hash to the WL mint. 
        //this.props.wlHash, this.props.wlSig);
      
      //const txReceipt = await tx.wait();
      console.log(`Minted ${this.state.numToMint} Hunky Hero, on the whitelist`);
    } catch (error) {
        if (error.message.includes("Presale is not live")) {
          this.setState({ errMsg: {code: "Presale is not live"} });
          console.error("Presale is not live");
        } else if (error.message.includes("whitelist already claimed")) {
          this.setState({ errMsg: {code: "Whitelist already claimed"} });
          console.error("Whitelist already claimed");
        } else {
          console.log("error during wl mint: ", error);
          this.setState({ errMsg: error });
      };
    };

    try {
      //update the token supply
      this.getTokenSupply();
    } catch (err) {
      console.log("error updating the contract's token supply: ", err);
    };
  };
  
  getTokenSupply() {
    // console.log("firing getTokenSupply() in MintBar");
    this.props.updateTokenSupply();
  };

  render() {
    //*debug*console.log("render MintBar constructor");
    return (
      <div className="mint-bar-content-wrap"> 
        <h2 className="text-center">
          Great work HERO - you're on the whitelist!
        </h2>
        {
          this.props.wlQuota > 1 &&
            //if the account has a quota > 1
            //let them mint more than one!
            <div className="mint-bar-number-input-wrap">
              <p className="text-no-margin-top">
                WL mints remaining: {this.props.maxNumber}
              </p>
              <NumberInput 
                formClassName="mint-bar-form" 
                inputClassName="input-num mint-bar-number" 
                //provider={this.props.provider} 
                formId={this.mintFormId_} 
                onNumChange={this.handleNumChange}
                onNumSubmit={this.handleMint} 
                storedValue={this.state.numToMint}
                maxNumber={this.props.maxNumber}
              />
            </div>
        }
        {
          !!this.state.errMsg && 
          <p>Error during mint!<br/>{this.state.errMsg.code}<br/>Press F12 for details</p>
        }
        <div className="mint-bar-button-wrap">
          <MintButton
            title={this.props.mintButtonMessage}
            buttonClassName={
              this.props.mintButtonClassName ?
              this.props.mintButtonClassName :
             "btn-type-a"
            }
            type="submit"
            formId={this.mintFormId_}
            //provider={this.props.provider}
            onMintSubmit={this.handleMint} 
          />
        </div>
      </div>
    );
  };
}

export class MintBar extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId_ = this.props.mintFormId;
    this.mintFormIdWL_ = this.props.mintFormIdWL
    //checkMinted is the number of WL mints the connected
    // account has made
    this.checkMinted = this.checkMinted.bind(this);
    this.state = { 
      accountHasMinted: ethers.BigNumber.from("0"),
      accountRemainingWl: ethers.BigNumber.from("0")
    };
  };
  
  async componentDidMount() {
    //console.log("componentDidMount");
    //console.log("account: ", this.props.account);
    await this.checkMinted(this.props.account);
    //console.log("minted: ", minted);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.account !== prevProps.account) {
      //console.log("componentDidUpdate");
      await this.checkMinted(this.props.account);
    }
  }

  componentWillUnmount() {
    this.setState({ 
      accountHasMinted: ethers.BigNumber.from("0"),
      accountRemainingWl: ethers.BigNumber.from("0")
    });
  }

  //check if the connected address has already used
  //up their whitelist allowance
  async checkMinted(account_) {
    if( whitelist.hasOwnProperty(account_) ) {
      const signer = await this.props.provider.getSigner()

      //connect to the contract
      // DEBUG: console.log("signer: ", signer);
      const heroesContract = new ethers.Contract(
        this.props.heroesAddress, 
        this.props.abis.heroes, 
        signer
      );

      // DEBUG: console.log("heroesContract: ", heroesContract);
      // console.log("heroesAddress: ", this.props.heroesAddress);
      // console.log("account_: ", account_);
      // console.log("Whitelist: ", whitelist);
      
      //how many WL tokens has the account minted?
      //how many WL tokens remain for the account?
      const accountHasMinted = await heroesContract.minted(account_);
      let accountRemainingWl;// = ethers.BigNumber.from("0");
      whitelist.hasOwnProperty(account_) 
      ? (accountRemainingWl = (await ethers.BigNumber.from(
          whitelist[account_]["quota"]))
          .sub(accountHasMinted))
      : ( accountRemainingWl = await ethers.BigNumber.from("0") )

      //console.log("DEBUG: this.state: ", this.state);
      this.setState({ 
        accountHasMinted: accountHasMinted, 
        accountRemainingWl: accountRemainingWl
      });
      //console.log("DEBUG: this.state: ", this.state);
    } else {
    //account is not on the whitelist
    //do not update the state
    }
  }

  render() {
    //*debug*console.log("render MintBar constructor");

    return (
      <div className="mint-bar-container">
        <h2 className="mint-bar-title">
          {this.props.title}
        </h2>
        {
          this.props.subtitle &&
            <h3 className="mint-bar-subtitle">{this.props.subtitle}</h3>
        }
        {
          this.props.message && 
            <div className="mint-bar-message-wrap">
              {
                this.props.message.firstline && 
                  <p className="mint-bar-message-first-line">
                    {this.props.message.firstline}
                  </p>
              }
              {
                this.props.message.nextline &&
                  <p className="mint-bar-message-next-line">
                    {this.props.message.nextline}
                  </p>
              }
            </div>
        }
         { 
          ( //CHECK IF CONNECTED WALLET IS ON THE WHITELIST
            whitelist.hasOwnProperty(this.props.account) 
            //AND HAS NOT CLAIMED ALL THEIR ALLOWANCE YET
            && (whitelist[this.props.account]["quota"] > 
              this.state.accountHasMinted)
          )
          &&
          //?
          <div>
            {/*
            {console.log("whitelist[this.props.account]['quota']: ", whitelist[this.props.account]["quota"])}
            {console.log("this.state.accountHasMinted: ", this.state.accountHasMinted)}
            {console.log("this.state.accountRemainingWl: ", this.state.accountRemainingWl)}
            */}
            <WhitelistMintingContent
              mintFormId={this.mintFormIdWL_}
              provider={this.props.provider}
              heroesAddress={this.props.heroesAddress}
              abis={this.props.abis}
              updateTokenSupply={this.props.updateTokenSupply}
              mintButtonMessage={"Claim your Hunk!"}
              mintButtonClassName={
              this.props.mintButtonClassName &&
              this.props.mintButtonClassName
              }
              wlSig={whitelist[this.props.account]["sig"]}
              wlQuota={whitelist[this.props.account]["quota"]}
              startNumber={this.state.accountRemainingWl.toNumber()}
              maxNumber={this.state.accountRemainingWl.toNumber()
                //whitelist[this.props.account]["quota"]
              }
            />
            <p>Or Mint some fresh hunks!<br/>just 0.02 ETH each!</p>
          </div>
          }
        {/*:*/}
           <MintingContent
             mintFormId={this.mintFormId_}
             provider={this.props.provider}
             heroesAddress={this.props.heroesAddress}
             abis={this.props.abis}
             updateTokenSupply={this.props.updateTokenSupply}
             mintButtonMessage={this.props.mintButtonMessage}
             mintButtonClassName={
               this.props.mintButtonClassName &&
               this.props.mintButtonClassName
             }
             maxNumber={this.props.maxNumber}
           />
         {/*}*/}
      </div>
    );
  };
}