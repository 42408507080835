import React from "react";
import { Config } from "../config/default.js";
import homepageLogo from "../media/homepageLogo.jpg";

//make a nav bar
export function NavBar(props) {
	return(
		//nav bar - top of the page
		//site logo on left - links on right
		<nav className="navbar-wrap flex-c-row-md-col full-width fix-top fix-right">
			{/*logo + homepage link*/}
			<a 
				href={Config.homepageURL} 
				target="_blank" 
				rel="noopener noreferrer"
			>
				<img 
					className="navbar-homepage-logo" 
					src={homepageLogo} 
					alt="Hunky Heroes"
				/>
			</a>
			{/*links*/}
			<div className="navbar-links-wrap flex-c-row-md-col">
				{props.children}
			</div>
		</nav>
	);
}

//scrolling behaviour for links
function handleScrollTo(eleId) {
	let ele = document.getElementById(eleId);
	ele.scrollIntoView({behavior: "smooth", block: "start"});
}

export function LinkButton(props) {
	return(
		<div className="link-button-wrap">
			<button
				className={props.buttonClass ? props.buttonClass : "btn-type-a"}
				onClick={() => {
					handleScrollTo(props.linkToId)
				}}
			>
				{props.buttonTitle}
			</button>
		</div>
	);
}

export function LinkCard(props) {
	//an image and text card with an external link

	return(
		<div className="link-card-wrap flex-col">
			<a 
				className="link-card-anchor"
				href={props._href} 
				target="_blank" 
				rel="noopener noreferrer"
			>
				<div className="link-card-image-wrap">
					<img 
						className="link-card-img"
						src={props.imgSrc} 
						alt={props.imgAlt}
					/>
				</div>
				<h4 className="link-card-title text-center">
					{props.cardTitle}
				</h4>
				<p className="link-card-text text-center">
					{props.cardText}
				</p>
			</a>
		</div>
	);
}

export function SocialLogoButton(props) {
	return(
		<div className="social-logo-btn">
			<a
				className="social-btn-anchor"
				href={props.href_}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="btn-ele btn-social-logo">
					{props.buttonLogo}
				</div>
			</a>
		</div>
	);
}

export function SocialButtonGroup(props) {
	return(
		<div className="social-btn-group">
			{props.children}
		</div>
	);
}

export function ExternalLinkLogoButton(props) {
	return(
		<div id="link-btn" className="ext-link-logo-btn">
			<a
				className="btn-anchor"
				href={props.href_}
				target="_blank"
				rel="noopener noreferrer"
			>
				<button
					className={props.buttonClass}
				>
					<div className="btn-ele btn-logo">
						{props.buttonLogo}
					</div>
					<div	className="btn-ele btn-title">
						{props.buttonTitle}
					</div>
				</button>
			</a>
		</div>
	);
}