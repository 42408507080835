import React from "react";

export function RoadmapItem(props) {
	//make an array of all the text lines in one RM item
	let items = [];
	for (let i = 0; i < props.lines.length; i++) {

		i % 2 === 0
			? items.push(
				<h4 className="roadmap-item-subtitle" key={i}>
					{props.lines[i]}
				</h4>
			) : items.push(
				<p className="roadmap-item-text" key={i}>
					{props.lines[i]}
				</p>
			)
	
		// items.push(

		// 	<p 
		// 		className={
		// 			i % 2 == 0 
		// 				? "roadmap-item-subtitle"
		// 				: "roadmap-item-text"
		// 			} 
		// 		key={i}>
		// 		{props.lines[i]}
		// 	</p>
		// );
	}

	return(
		<div className={
			props.className
				? "roadmap-item-wrap " + props.className
				: "roadmap-item-wrap"
			}
		>
			<h3 className="roadmap-item-title">
				{props.title}
			</h3>
			{items}
		</div>
	);
}

export function RoadmapContent(props) {
	//all the roadmap items
	//does not include section title etc
	return(
		<div className="roadmap-content-wrap posn-relative">
			<RoadmapItem
				title={"0%: The Hunky Heroes are born"}
				lines={[
					"Welcome Hunksters, to the Hunkiest collection of all",
					"The collection is off to a flying start with the first 500 mints going FREE to Discord members"
				]}
			/>
{/*			<RoadmapItem
			title={"25%: The Community Wallet is opened"}
				lines={[
					"Keep the project flying - 2 ETH dropped in!",
					"The community wallet will be used to keep this project Heroic",
					"Heroes don't ask for rewards, but they deserve them",
					"Community giveaways for active Discord members"
				]}
			/>*/}
			<RoadmapItem
			title={"25%: It Pays To Be A Hero"}
				lines={[
					"Heroes don't ask for rewards, but they deserve them",
					"ETH Giveaways for Hunky Hero Holders!",
					"Keep the project flying: Community Wallet Opened",
					"The community wallet will be used to keep this project Heroic"
				]}
			/>
			<RoadmapItem
				title={"50%: Enter the Hunkyverse"}
				lines={[
					"Inception of the HH Comic Universe",
					"Official Comic Whitelist given to 50 lucky holders!"
				]}
			/>
			<RoadmapItem
				title={"75%: Saving Lives"}
				lines={[
					"Hunks Are Heroes",
					"ETH Donation to charity chosen by the community",
				]}
			/>
			<RoadmapItem
				title={"100%: Ink Me Up"}
				lines={[
					"Hunks For Life",
					"Hunky Founders will get Hunky Hero Tattoos based on community poll"
				]}
			/>
			<h2 className="text-center">
				Stratosphere Breached
			</h2>
			<h4 className="text-center">
				Selling out the Hunks will enable us to roll out the master plan!
			</h4>
			<RoadmapItem
				title={"Phase II"}
				lines={[
					"Official Hunky Heroes Comic lands",
					"Released initially as an NFT, with hard copies to follow"
				]}
			/>
			<RoadmapItem
				title={"Phase III"}
				lines={[
					"Hunky Heroes DAO",
					"Use your powers for good! The community takes charge of the Hunky DAO"
				]}
			/>
			<RoadmapItem
				title={"Phase IV"}
				lines={[
					"Hunky Heroines & Citysville Civilians",
					"The much awaited HEROINES and CIVILIANS land with 2 new collections!"
				]}
			/>
			<RoadmapItem
				title={"Phase V"}
				lines={[
					"The Hunky Heroes Show",
					"We are SO excited to bring the NFT world into the entertainment world, bringing Hunky Heroes and NFTs to a mainstream audience!"
				]}
			/>
			<RoadmapItem
				title={"Phase VI"}
				lines={[
					"Wen $HUNK?",
					"Being a $HUNK is all in a day's work for a Hero, but civilians gotta have stacks. Rolling out our $HUNK token for purchases in the Hunkyverse, think: comics, merch, action figures..."
				]}
			/>

		</div>
	);
}